import React from 'react';

const Stories = ({data}) => {
  return (

        <li className='global-newscard--list'>
            <div className='global-newscard--listimage'>
                <img src={data?.image} alt="story image" draggable="false"/>
            </div>
            <div className='global-newscard--listcontent'>
                <h3 className="global-newscard--listtitle">{data?.title}</h3>
                <p className="global-bodytext global-newscard--listdesc">
                    {data?.desc}
                </p>
                <button type='button' className='global-newscard--listbtn'>Read Full Article</button>
            </div>
        </li>

  )
}

export default Stories