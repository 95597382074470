import React from 'react'

const MenuCards = ({data, handleMenuClick, themeColor}) => {
  return (
    <>
        <ul className='sh-menuCard'>
            {data && data.length > 0 && data.map((item, index) => (
                <li key={item.id} className={`sh-menuCard--grid ${themeColor}`} onClick={() => handleMenuClick(item?.route)}>
                    <p className='sh-menuCard--gridcontent mb-0 '>{item?.name}</p>
                </li>
            ))}
        </ul>
    </>
  )
}

export default MenuCards