import React from 'react';
import './style.scss';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import threemonth from "../../assets/images/threemonth.png";
import OwlCarousel from "react-owl-carousel";
import CarouselBanner from "../../../src/assets/images/banner1.jpg";
import INDUSTRY_CONNECTIONS from '../../dummyData/IndustryConnections.json';
import PARTNERS_REVIEW_DATA from '../../dummyData/PartnersReview.json';
import { Carousel } from "primereact/carousel";
import { Link } from "react-router-dom";
import CollageCarousel from '../../components/sections/CollageCarousel';


const InternshipThreeMonth = () => {

    const responsiveOptions = [
        {
          breakpoint: '3600px',
          numVisible: 4,
          numScroll: 1,
        },
        {
          breakpoint: '1200px',
          numVisible: 3,
          numScroll: 1,
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1,
        }
    ];

    const partnersReviewTemplate = (item) => {
        return (
          <div className="in-insights--carouselitem in-partners--carousel-item mx-sm-2">
            <img
              className="in-insights--carouselitem--imgfull" alt="Profile Pic"
              src={item?.profilePic}
            />
            <h3 className="global-subheading global-fw600 mb-4">{item?.name}</h3>
            <p className="in-insights--carouselitem--desc">{item?.description}</p>
            <button className="in-insights--carouselitem--btn">
              Read More
            </button>
          </div>
        );
    };

    const studentsExpTemplate = (item) => {
        return (
          <div className="in-insights--carouselitem in-partners--carousel-item mx-2">
            <img
                className="in-completed--griditem--img"
                src={item?.profilePic}
                alt="profile-pic" draggable="false"
            />
            <h3 className="global-subheading global-fw600">{item?.name}</h3>
            <p className="global-bodytext mb-3">Student Batch</p>
            <img
              className="in-insights--carouselitem--imgfull" alt="Profile Pic"
              src={item?.profilePic} draggable='false'
            />
            <p className="in-insights--carouselitem--desc">{item?.description}</p>
            <button className="in-insights--carouselitem--btn">
              Read More
            </button>
          </div>
        );
    };

  return (
    <>
        {/* Section: Banner */}
        <section className='mb-5'>
            <SecondaryBanner
                imageUrl= {threemonth} 
                title= "Placements - 3 months Internship"
                desc= "Building Foundations with Summer Internships."
                themeColor="sh-contentimgsplit--red700-theme"
            />
        </section>

        {/* Section: Building Real World */}
         <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                        Building Real-World Skills from Year One
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <h4 className='global-subheading mb-4'>Summer Internships for a Head Start</h4>
                        <p className="global-bodytext">
                        At Sitare University, we believe in providing students with practical experience from the very start. Our 3-month summer internships allow 1st and 2nd-year students to immerse themselves in professional environments, explore potential career paths, and gain hands-on experience that complements their academic learning.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        {/* Section: Purpose of Internship */}
        <section className="cp-whyinternship">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <div className="cp-card">
                            <h1 className='global-heading mb-4'>Purpose of Summer Internships</h1>
                            <p className='global-bodytext'>
                            Summer internships are designed to bridge the gap between classroom learning and industry practices. By working directly with professionals in their fields, students can begin to develop essential skills, build confidence, and explore areas of interest early in their academic journey.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div className="cp-card">
                            <h1 className='global-heading mb-4'>Internship<br/> Opportunities</h1>
                            <p className='global-bodytext'>
                            Sitare University partners with a diverse array of organizations, offering students internships in software development, data science, product design, research, and more. Whether you’re interning at a tech company, a research institute, or an innovative startup, there’s no shortage of exciting opportunities.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Section: Top Carousel End*/}

        {/* Section: Industry Partners */}
        <section className="container space_bottom">
            <div className="global-primeCarousel">
                <div className="row">
                    <div className="col-12 pt-0 mb-5">
                        <h1 className="global-heading mb-4">
                            partner companies
                        </h1>
                        <p className="global-bodytext">
                            Our internship partners include well-known companies, forward-thinking startups, and research institutions committed to helping students grow. These partners offer valuable insights into industry trends and often provide a pathway for future employment.
                        </p>
                    </div>
                </div>

                <CollageCarousel data={INDUSTRY_CONNECTIONS} />
            </div>
        </section>


        {/* Section: Industry Partners Says */}
        <section className="in-insights in-partners">
            <h1 className="global-heading in-partners--bottomspace text-center">
            What our industry partners say
            </h1>

            <div>
            <Carousel
                value={PARTNERS_REVIEW_DATA}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                className="custom-carousel in-insights--internship-carousel"
                circular={false}
                showIndicators={false}
                autoplayInterval={0}
                itemTemplate={partnersReviewTemplate}
                prevIcon={<i className="fa-solid fa-angle-left"></i>}
                nextIcon={<i className="fa-solid fa-angle-right"></i>}
                indicatorsContentClassName="gdhshds"
            />
            </div>
        </section>

         {/* Section: Student Support */}
         <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                        Student Support & Guidance
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <p className="global-bodytext">
                        From resume workshops to mock interviews, we support our students every step of the way. Our faculty mentors offer personalized guidance, helping students maximize their internship experiences and develop a roadmap for their careers.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        {/* Section: Enquire Now */}
        <section className="global-blue--strip mt-md-5">
        <div className="container">
          <div className="d-flex align-items-center gap-5">
            <div>
              <p className="global-blue--stripapplytitle">Want to know more?</p>
              <h2 className="global-blue--stripapplybtn">Enquire Now</h2>
            </div>
            
            <Link to={"https://admissions.sitare.org/"} target="_blank" className="global-blue--striparrow">
              <i className="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </section>


        {/* Section: Student Experiences */}
        <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                            Student <br/> Experiences
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <p className="global-bodytext">
                            Hear from our students! These firsthand stories highlight the practical skills, professional connections, and unforgettable experiences our students have gained through summer internships.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        {/* Section: Industry Partners Says */}
        <section className="in-insights">
            <div>
            <Carousel
                value={PARTNERS_REVIEW_DATA}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                className="custom-carousel in-insights--internship-carousel"
                circular={false}
                showIndicators={false}
                autoplayInterval={0}
                itemTemplate={studentsExpTemplate}
                prevIcon={<i className="fa-solid fa-angle-left"></i>}
                nextIcon={<i className="fa-solid fa-angle-right"></i>}
                indicatorsContentClassName="gdhshds"
            />
            </div>
        </section>

    </>
  )
}

export default InternshipThreeMonth;