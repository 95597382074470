import React from 'react'
import './style.scss';
import AcademicImg from "../../assets/images/academic_life.png";
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import MenuCards from '../../components/sections/MenuCards';
import { PROJECTS_MENU_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const Projects = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        if(route.includes("http:") || route.includes("https:")){
            window.open(route, "_blank")
        }else{
            navigate(`/${route}`);
        }
    }

    return (
        <>
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl= {AcademicImg} 
                    title= "academic life - Projects"
                    desc= "Turning Concepts into Reality: Explore, Create, Share"
                    themeColor="sh-contentimgsplit--yellow-theme"
                />
            </section>
            <section className='container py-lg-5 mb-5'>
                <MenuCards data={PROJECTS_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--yellow-theme" />
            </section>
        </>
    )
}

export default Projects;