import React from 'react'
import "./index.scss"

const SecondaryBanner = ({imageUrl, title, desc, themeColor}) => {
  return (
    <div className='sh-contentimgsplit'>
        <div className={`sh-contentimgsplit--content ${themeColor}`}>
          <div className='sh-contentimgsplit--contain'>
          <p className='sh-contentimgsplit--content-title'>{title}</p>
          <p className='sh-contentimgsplit--content-desc'>{desc}</p>
          </div>
        </div>
        <div className='sh-contentimgsplit--image'>
            <img className='sh-contentimgsplit--imageimg' src={imageUrl} alt='banner' draggable="false"/>
        </div>
    </div>
  )
}

export default SecondaryBanner;