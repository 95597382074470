import React from "react";
import "./style.scss";
import FacilityImg from "../../assets/images/university/campus1.jpg";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import MenuCards from "../../components/sections/MenuCards";
import { FACILITIES_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const Facilities = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank");
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <>
      <section className="mb-5">
        <SecondaryBanner
          imageUrl={FacilityImg}
          title="University Life - Facilities"
          desc="Campus Experience: Where Learning Meets Living."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>
      {/* Section: Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <h1 className="global-heading">
                    Headline to be provided by Siddhanth
                  </h1>
                </div>
                <div className="col-md-6 col-12">
                  <p className="global-bodytext mb-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.Sed ut
                    perspiciatis unde omnis iste natus error sit voluptatem
                    accusantium doloremque laudantium, totam rem aperiam.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container py-lg-5 mb-5 se-cards">
        <MenuCards
          data={FACILITIES_MENU_DATA}
          handleMenuClick={handleMenuClick}
          themeColor="sh-menuCard--orange-theme"
        />
      </section>
    </>
  );
};

export default Facilities;
