import React from 'react';
import "../../assets/styles/styles.scss";
import CrossIcon from '../../assets/images/cross.png'
import ImgDummy from '../../assets/images/rectangle.png'


const InspiringStoriesDetail = ({item }) => {
    return (
        <div className="offcanvas offcanvas-end is-details" tabindex="-1" id={`InspiringStoriesDetail${item?.id}`} aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header justify-content-start">
                <img src={CrossIcon} alt='icon' draggable="false" data-bs-dismiss="offcanvas" className='wu-vacancy--closeicon' />
            </div>
            <div className="offcanvas-body is-details--body px-5">
                <h1 className="global-heading mb-4">{item?.studentName}</h1>

                <h3 className="global-subheading st-stories--item-subtitle">{item?.studentBatch}</h3>
                <p className="global-bodytext st-stories--item-desc">
                    {item?.desc}
                </p>

                <div className='is-details--images'>
                    <img src={ImgDummy} alt='Stories Image' draggable="false" />
                    <img src={ImgDummy} alt='Stories Image' draggable="false" />
                </div>
                
            </div>
        </div>
    )
}

export default InspiringStoriesDetail