// menuItems.js
export const getMenuItems = (navigate) => [
  // Acadmic Life Menu Items
  {
    label: "Academic Life",
    icon: "",
    items: [
      {
        label: "Academic Life",
        icon: "",
        items: [
          {
            label: "Academic Life",
            command: () => {
              navigate("/academic-Life");
            },
          },
        ],
      },
      // {
      //   label: "Courses",
      //   icon: "",
      //   items: [
      //     {
      //       label: "Project",
      //       command: () => {
      //         navigate("/projects");
      //       },
      //     },
      //     {
      //       label: "Student projects",
      //       command: () => {
      //         navigate("/student-projects");
      //       },
      //     },
      //     { label: "Sitare github" },
      //     { label: "Sitare hugging face",
      //       command: () => {
      //         window.open('https://huggingface.co/', '_blank');
      //       },
      //      },
      //     {
      //       label: "Faculty projects",
      //       command: () => {
      //         navigate("/faculty-projects");
      //       },
      //     },
      //   ],
      // },
      // {
      //   label: "Admissions",
      //   icon: "",
      //   items: [
      //     { label: "How to apply",
      //       command: () => {
      //         window.open('https://admissions.sitare.org/', '_blank');
      //       },
      //      },
      //     {
      //       label: "Placements",
      //       command: () => {
      //         navigate("/placements");
      //       },
      //     },
      //     {
      //       label: "Contact Us",
      //       command: () => {
      //         navigate("/contact-us");
      //       },
      //     },
      //   ],
      // },
      {
        label: "Academic Life",
        icon: "",
        items: [
          {
                  label: "Curriculum",
                  command: () => {
                    navigate("/curriculum");
                  },
                },
          {
                  label: "Student Projects",
                  command: () => {
                    navigate("/placements");
                  },
                },
        ],
      },
    ],
    command: () => {
      // Callback to run
    },
  },

  //   University Menu Items
  {
    label: "University life",
    icon: "",
    items: [
      {
        label: "University life",
        items: [
          {
            label: "University life",
            icon: "",
            command: () => {
              navigate("/facilities");
            },
          },
        ],
      },
      {
        label: "Our Campus",
        icon: "",
        items: [
          {
            label: "Hostels",
            icon: "",
            command: () => {
              navigate("/hostels");
            },
          },
          {
            label: "Cafeteria",
            icon: "",
            command: () => {
              navigate("/cafeteria");
            },
          },
          {
            label: "Gym",
            icon: "",
            command: () => {
              navigate("/gym");
            },
          },
          {
            label: "Sports",
            icon: "",
            command: () => {
              navigate("/sports");
            },
          },
          // {
          //   label: "Auditorium",
          //   icon: "",
          //   command: () => {
          //     navigate("/auditorium");
          //   },
          // },
        ],
      },
      {
        label: "Activities",
        icon: "",
        items: [
          {
            label: "Clubs",
            command: () => {
              navigate("/clubs");
            },
          },
          // {
          //   label: "Events",
          //   command: () => {
          //     navigate("/university-event");
          //   },
          // },
          // {
          //   label: "Activities",
          //   command: () => {
          //     navigate("/class-activities");
          //   },
          // },
          // {
          //   label: "Fests",
          //   command: () => {
          //     navigate("/fests");
          //   },
          // },
          {
            label: "Sports",
            command: () => {
              navigate("/sports");
            },
          },
        ],
      },
      // {
      //   label: "Hackathon",
      //   icon: "",
      //   items: []
      // }
    ],
    command: () => {
      // Callback to run
    },
  },
  //   Placement Menu Items
  // {
  //   label: "Placements",
  //   icon: "",
  //   items: [
  //     {
  //       label: "placements",
  //       items: [
  //         {
  //           label: "Placements",
  //           icon: "",
  //           command: () => {
  //             navigate("/placements");
  //           },
  //         },
  //       ],
  //     },
  //   ],
  // },

  //   About Us Menu Item
  {
    label: "About Us",
    icon: "",
    items: [
      // { label: "About Us", icon: "" },
      {
        label: "About Us",
        icon: "",
        command: () => {
          navigate("/about");
        },
        items: [
          {
            label: "About Us",
            command: () => {
              navigate("/about");
            },
          },
        ],
      },
      [
        {
          label: "Who We Are",
          icon: "",
          items: [
            {
              label: "Why Sitare",
              command: () => {
                navigate("/why-sitare");
              },
            },
            {
              label: "Who We Are",
              command: () => {
                navigate("/who-we-are");
              },
            },
            {
              label: "Our Campus",
              command: () => {
                navigate("/facilities");
              },
            },
            {
              label: "Our People",
              command: () => {
                navigate("/our-people");
              },
            },
          ],
        },
        // {
        //   label: "Alumni",
        //   icon: "",
        //   items: [
        //     {
        //       label: "Alumni",
        //       command: () => {
        //         navigate("/alumni");
        //       },
        //     },
        //     {
        //       label: "Stay Connected",
        //       command: () => {
        //         navigate("/stay-connected");
        //       },
        //     },
        //     { label: "Alumni Events",
        //       command: () => {
        //         navigate("/alumni-events");
        //       }
        //      },
        //   ],
        // },
      ],
      // [
      //   {
      //     label: "Our People",
      //     icon: "",
      //     items: [
      //       { label: "Advisors" },
      //       { label: "Faculty" },
      //       // { label: "Administration" },
      //       { label: "Students" },
      //       { label: "Staffs" },
      //     ],
      //   },
      //   {
      //     label: "Work With Us",
      //     icon: "",
      //     items: [
      //       {
      //         label: "Academic Vacancies",
      //         command: () => {
      //           navigate("/academic-vacancies");
      //         },
      //       },
      //       {
      //         label: "Administrative Vacancies",
      //         command: () => {
      //           navigate("/administrative-vacancies");
      //         },
      //       },
      //     ],
      //   },
      // ],
      // [
      //   {
      //     label: "Our Campus",
      //     icon: "",
      //     items: [{ label: "Current Campus" },],
      //   },
      //   {
      //     label: "Gallery",
      //     icon: "",
      //     items: [
      //       {
      //         label: "Gallery",
      //         command: () => {
      //           navigate("/alumni-gallery");
      //         },
      //       },
      //     ],
      //   },
      // ],
    ],
    command: () => {
      // Callback to run
    },
  },

  //   Contact Us Menu Item
  {
    label: "Contact Us",
    icon: "",
    items: [
      {
        label: "Contact Us",
        command: () => {
          navigate("/contact-us");
        },
        items: [
          {
            label: "Contact Us",
            command: () => {
              navigate("/contact-us");
            },
          },
        ],
      },
    ],
  },

  //  Apply Now Section
  // {
  //   label: "Apply Now",
  //   icon: "",
  //   command: () => {
  //     window.open('https://admissions.sitare.org/', '_blank');
  //   },
  // },
   //  News & Update Section
  //  {
  //   label: "News & Update",
  //   icon: "",
  //   command: () => {
  //     navigate("/news-updates");
  //   },
  // },
     //  Events Section
    //  {
    //   label: "Events",
    //   icon: "",
    //   command: () => {
    //     navigate("/events-activities");
    //   },
    // },
       //  Blog Section
  //  {
  //   label: "Blogs",
  //   icon: "",
  //   command: () => {
  //     navigate("/blog");
  //   },
  // },
     //  Number Section
    //  {
    //   label: "+91 7829758672",
    //   icon: "",
    //   command: () => {
    //     navigate("");
    //   },
    // }

];
