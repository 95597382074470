import React from 'react';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import placementBanner from "../../assets/images/placementBanner.png";
import MenuCards from '../../components/sections/MenuCards';
import { PLACEMENTS_CARDS_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const Placements = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        navigate(`/${route}`);
    }

    return (
        <>
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl= {placementBanner} 
                    title= "Placements"
                    desc= "Empowering Careers Through Practical Learning."
                    themeColor="sh-contentimgsplit--red700-theme"
                />
            </section>
            <section className='container py-lg-5 mb-5'>
                <MenuCards data={PLACEMENTS_CARDS_DATA} handleMenuClick={handleMenuClick} />
            </section>
        </>
    )
}

export default Placements