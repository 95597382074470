// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.al-topics {
  padding-left: 40px;
  margin: 40px auto 70px;
}
.al-topics li:not(:last-child) {
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/alumni-benefits/style.scss"],"names":[],"mappings":"AAGI;EACI,kBAAA;EACA,sBAAA;AAFR;AAIQ;EACI,mBAAA;AAFZ","sourcesContent":["@import '../../assets/styles/colors';\n\n.al{\n    &-topics{\n        padding-left: 40px;\n        margin: 40px auto 70px;\n\n        li:not(:last-child){\n            margin-bottom: 15px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
