import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import alumniEvents from "../../assets/images/alumniEvents.png";
import gallery1 from "../../assets/images/cafe.png";
import gallery2 from "../../assets/images/class_room.png";
import gallery3 from "../../assets/images/gym.png";
import { useNavigate } from "react-router-dom";

const AlumniGallery = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Section:Banner */}
      <section>
        <SecondaryBanner
          imageUrl={alumniEvents}
          title="About Us - Alumni - Gallery"
          desc="Memories in Focus - Alumni Moments"
          themeColor="sh-contentimgsplit--green500-theme"
        />
      </section>

      {/* Section: Gallery Filter */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="al-filters">
                <div className="al-filters--wrapper">
                <p className="mb-0 bl-filters--text">Filter by</p>
                <select className="al-filters--select">
                  <option>This Year</option>
                  <option>Year 1</option>
                  <option>Year 2</option>
                </select>
                <select className="al-filters--select">
                  <option>Event Name</option>
                  <option>Topic 1</option>
                  <option>Topic 2</option>
                </select>
                </div>
              

                <button type="button" className="mb-0 al-filters--text">Reset Filters</button>
              </div>
            </div>
          </div>
        </div>

        {/* Section: Gallery Cards */}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading global-fwbold my-5">
                Annual Alumni Meet 2023
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="al-gallery">
                  <div className="al-gallery--image">
                      <img src={gallery1} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery2} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery3} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery2} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery3} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery1} alt="gallery-image" draggable="false" />
                  </div>
              </div>
            </div>
          </div>
        </div>

         {/* Section: Gallery Cards */}
         <div className="container mt-5">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading global-fwbold my-5">
                Coffee Club 2023
              </h1>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="al-gallery">
                  <div className="al-gallery--image">
                      <img src={gallery1} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery2} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery3} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery2} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery3} alt="gallery-image" draggable="false" />
                  </div>
                  <div className="al-gallery--image">
                      <img src={gallery1} alt="gallery-image" draggable="false" />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AlumniGallery;
