import React from "react";
import { Link } from "react-router-dom";
import MenuCards from "../../components/sections/MenuCards";
import { MENU_CARDS_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import BannerImg from "../../assets/images/work_with_us_banner.png";
import bgImg from "../../assets/images/bg_work.png";
import "./style.scss";
import "../../assets/styles/styles.scss";

const WorkWithUs = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if(route.includes("http:") || route.includes("https:")){
      window.open(route, "_blank")
    }else{
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="about us - work with us"
          desc="Join the Mission at Sitare"
          themeColor="yellow-theme"
        />
      </section>

      {/* Section: After Banner Text */}
      <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="global-aboutwrapper">
              <p className="global-bodytext">
                At Sitare University, we are on a mission to revolutionize
                Computer Science education in India. Founded by Dr. Amit Singhal
                in 2022, our goal is to provide world-class education to
                brilliant students from economically disadvantaged backgrounds.
                We believe in the power of education to change lives, and we
                invite passionate educators, industry professionals, and
                dedicated staff to join us in this transformative journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Vacancy */}
      <section className="container py-md-5">
        <div className="row">
          <div className="col-md-6 col-12 px-md-0">
            <div className="wu-main--vacancy">
              <Link to={"/administrative-vacancies"}>
                Administrative
                <br /> Vacancy
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-12 px-md-0">
            <div className="wu-main--vacancy wu-main--academic">
              <Link to={"/academic-vacancies"}>
                Academic
                <br /> Vacancy
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Work With Us Heading */}
      <section className="container">
        <div className="row my-lg-5">
          <div className="col-12">
            <div className="wu-main--workus">
              <h1 className="global-heading mb-4">Why Work With Us?</h1>
              <h6 className="global-subheading global-fw600 mb-5">
                A Unique Opportunity to Make a Difference
              </h6>
              <p className="global-bodytext py-3">
                Working at Sitare University offers a unique opportunity to be
                part of an institution that is not only committed to academic
                excellence but also to social upliftment.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: how to apply */}
      <section className="global-blue--strip my-5">
        <div className="container">
          <div className="d-flex align-items-center gap-5">
            <div>
              <p className="global-blue--stripapplytitle">Vacancies</p>
              <h2 className="global-blue--stripapplybtn">How To Apply</h2>
            </div>
            <Link to={"/"} className="global-blue--striparrow">
              <i className="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </section>

      {/* Section: Work With Us Options */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-12 px-sm-0">
              <div className="wu-main--workvalues">
                <h2>
                  Impactful
                  <br /> Mission
                </h2>
                <p>
                  Be part of a mission-driven institution that aims to empower
                  underprivileged students through top-tier education.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-sm-0">
              <div className="wu-main--workvalues red800">
                <h2>
                  Innovative
                  <br /> Environment
                </h2>
                <p>
                  Engage with an industry-focused curriculum designed to equip
                  students with practical skills and real-world experience.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-sm-0">
              <div className="wu-main--workvalues">
                <h2>
                  Collaborative
                  <br /> Culture
                </h2>
                <p>
                  Work alongside passionate educators, industry experts, and a
                  diverse group of professionals dedicated to student success.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12 px-sm-0">
              <div className="wu-main--workvalues red800">
                <h2>
                  Professional
                  <br /> Growth
                </h2>
                <p>
                  Benefit from ongoing professional development opportunities
                  and the chance to work on cutting-edge projects and research.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="wu-main--bgwork">
          <img src={bgImg} alt="background image" draggable="false" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 wu-main--core">
              <ul className="wu-main--corevalues">
                <li>
                  <h1 className="global-heading">our core<br/> values</h1>
                </li>
                <li>
                  <h4>excellence in education</h4>
                  <p className="global-bodytext">
                    Commitment to providing a world-class education that equips
                    students with the knowledge and skills to succeed in the
                    industry.
                  </p>
                </li>
                <li>
                  <h4>Inclusivity and Diversity</h4>
                  <p className="global-bodytext">
                    Creating an inclusive and diverse learning environment where
                    students from all backgrounds feel welcome and supported.
                  </p>
                </li>
                <li>
                  <h4>Social Impact</h4>
                  <p className="global-bodytext">
                    Dedication to driving social upliftment by offering quality
                    education to bright students from economically disadvantaged
                    backgrounds.
                  </p>
                </li>
                <li>
                  <h4>Innovation and Industry Relevance</h4>
                  <p className="global-bodytext">
                    Fostering a culture of innovation and ensuring that the
                    curriculum is aligned with the latest industry trends and
                    technologies.
                  </p>
                </li>
                <li>
                  <h4>Personalized Attention and Support</h4>
                  <p className="global-bodytext">
                    Providing individualized attention and mentorship to ensure
                    that every student can reach their full potential.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section:Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
            <div className="row">
                <div className="col-12">
                <MenuCards data={MENU_CARDS_DATA} handleMenuClick={handleMenuClick} />
                </div>
            </div>
        </div>
       
      </section>
    </>
  );
};

export default WorkWithUs;
