// menuItems.js
export const getMenuItems = (navigate) => [
  // Acadmic Life Menu Items
  {
    label: "Academic Life",
    icon: "",
    items: [
      {
        label: "Academic Life",
        icon: "",
        items: [
          {
            label: "Academic Life",
            command: () => {
              navigate("/academic-Life");
            },
          },
        ],
      },
      {
        label: "Courses",
        icon: "",
        items: [
          {
            label: "Project",
            command: () => {
              navigate("/projects");
            },
          },
          {
            label: "Student project",
            command: () => {
              navigate("/student-projects");
            },
          },
          { label: "Sitare github" },
          { label: "Sitare hugging face" },
          {
            label: "Faculty projects",
            command: () => {
              navigate("/faculity-projects");
            },
          },
        ],
      },
      {
        label: "Admissions",
        icon: "",
        items: [
          { label: "How to apply",
            command: () => {
              window.open('https://admissions.sitare.org/', '_blank');
            },
           },
          {
            label: "Placements",
            command: () => {
              navigate("/placements");
            },
          },
          {
            label: "Contact Us",
            command: () => {
              navigate("/contact-us");
            },
          },
        ],
      },
      {
        label: "Academic Calender",
        icon: "",
        items: [{ label: "Time Table" }],
      },
    ],
    command: () => {
      // Callback to run
    },
  },

  //   University Menu Items
  {
    label: "University life",
    icon: "",
    items: [
      {
        label: "University life",
        items: [
          {
            label: "University life",
            icon: "",
            command: () => {
              navigate("/university-life");
            },
          },
        ],
      },
      {
        label: "Facilites",
        icon: "",
        items: [
          {
            label: "Hostels",
            icon: "",
            command: () => {
              navigate("/hostels");
            },
          },
          {
            label: "Cafeteria",
            icon: "",
            command: () => {
              navigate("/cafeteria");
            },
          },
          {
            label: "Gym",
            icon: "",
            command: () => {
              navigate("/gym");
            },
          },
          {
            label: "Sports",
            icon: "",
            command: () => {
              navigate("/sports");
            },
          },
          {
            label: "Auditorium",
            icon: "",
            command: () => {
              navigate("/auditorium");
            },
          },
        ],
      },
      {
        label: "Events & Activities",
        icon: "",
        items: [
          {
            label: "Clubs",
            command: () => {
              navigate("/clubs");
            },
          },
          {
            label: "Events",
            command: () => {
              navigate("/university-event");
            },
          },
          {
            label: "Activities",
            command: () => {
              navigate("/class-activities");
            },
          },
          {
            label: "Fests",
            command: () => {
              navigate("/fests");
            },
          },
          {
            label: "Sports",
            command: () => {
              navigate("/eventsports");
            },
          },
        ],
      },
      {
        label: "Hackathon",
        icon: "",
        items: []
      }
    ],
    command: () => {
      // Callback to run
    },
  },
  //   Placement Menu Items
  {
    label: "Placements",
    icon: "",
    items: [
      {
        label: "placements",
        items: [
          {
            label: "Placements",
            icon: "",
            command: () => {
              navigate("/placements");
            },
          },
        ],
      },
    ],
  },

  //   About Us Menu Item
  {
    label: "About Us",
    icon: "",
    items: [
      // { label: "About Us", icon: "" },
      {
        label: "About Us",
        icon: "",
        command: () => {
          navigate("/about");
        },
        items: [
          {
            label: "About Us",
            command: () => {
              navigate("/about");
            },
          },
        ],
      },
      [
        {
          label: "Who We Are",
          icon: "",
          items: [
            {
              label: "Who We Are",
              command: () => {
                navigate("/who-we-are");
              },
            },
          ],
        },
        {
          label: "Alumni",
          icon: "",
          items: [
            {
              label: "Alumni",
              command: () => {
                navigate("/alumni");
              },
            },
            {
              label: "Stay Connected",
              command: () => {
                navigate("/stay-connected");
              },
            },
            { label: "Alumni Events",
              command: () => {
                navigate("/alumni-events");
              }
             },
          ],
        },
      ],
      [
        {
          label: "Our People",
          icon: "",
          items: [
            { label: "Advisors" },
            { label: "Faculties" },
            { label: "Administration" },
            { label: "Students" },
            { label: "Staffs" },
          ],
        },
        {
          label: "Work With Us",
          icon: "",
          items: [
            {
              label: "Academic Vacancies",
              command: () => {
                navigate("/academic-vacancies");
              },
            },
            {
              label: "Administrative Vacancies",
              command: () => {
                navigate("/administrative-vacancies");
              },
            },
          ],
        },
      ],
      [
        {
          label: "Our Campus",
          icon: "",
          items: [{ label: "Current Campus" },],
        },
        {
          label: "Gallery",
          icon: "",
          items: [
            {
              label: "Gallery",
              command: () => {
                navigate("/alumni-gallery");
              },
            },
          ],
        },
      ],
    ],
    command: () => {
      // Callback to run
    },
  },

  //   Contact Us Menu Item
  {
    label: "Contact Us",
    icon: "",
    items: [
      {
        label: "Contact Us",
        command: () => {
          navigate("/contact-us");
        },
        items: [
          {
            label: "Contact Us",
            command: () => {
              navigate("/contact-us");
            },
          },
        ],
      },
    ],
  },

  //   Search Section
  {
    label: "Apply",
    icon: "",
    command: () => {
      window.open('https://admissions.sitare.org/', '_blank');
    },
  },

  // Apply Now Section
];
