import React from 'react';
import './style.scss';

const UpcomingActivities = () => {
  return (
    <>
      {/* Section: Upcoming Activities Details  */}
      <section className='ua-upcoming'>
        <section className="container">
          <h1 className="global-heading mb-4">
            Debate competition
          </h1>

          <p className='ua-upcoming--text mb-4'>Topic: </p>
          <p className='ua-upcoming--text mb-4'>Date: </p>
          <p className='ua-upcoming--text mb-4'>Status: </p>

          <div className='px-2'>
            <p>
              Sitare University hosted a gripping Debate Competition centered on the provocative topic, "A job interview is usually a dishonest conversation, with the interviewer claiming that their company or team has the best work culture and the interviewee claiming that s/he will turn out to be their best employee." Initially, all students submitted 200-word write-ups on this theme, from which the top 12 submissions advanced to the final debate. These finalists were divided into 4 teams—2 advocating for the motion and 2 against it.
            </p>
            <p>
              The competition unfolded over three rounds: each team presented their unique perspectives, engaged in head-to-head clashes with opposing teams, and finally, the audience weighed in on the arguments presented. Ultimately, the winning team comprised Divyansh Mishra from the 2nd year, and Satyam Pandey and Mallesh Kamati from the 1st year.
            </p>
            <p>
              Throughout the debate, the central theme underscored the importance of conducting interviews as candid conversations rather than platforms for deception. The discussion also debunked the notion that "faking it till you make it" is a viable strategy, revealing how dishonesty can create misleading impressions and hinder genuine success in life.
            </p>
          </div>
        </section>
      </section>
    </>
  )
}

export default UpcomingActivities;