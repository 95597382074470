import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import uniEvents from "../../assets/images/uniEvents.png";
import EVENTS_DATA from "../../dummyData/UniversityEventsData.json";
import MenuCards from "../../components/sections/MenuCards";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const UniversityEvent = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    navigate(`/${route}`);
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={uniEvents}
          title="University Life - Events & Activities - Events"
          desc="Celebrate. Share. Inspire."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Beyond Classroom */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">
                    Discover, Engage, and Celebrate together.
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    At Sitare University, our campus is always alive with a wide
                    range of events that inspire, engage, and bring the
                    community together. From academic conferences and cultural
                    fests to workshops and sports meets, our events provide
                    students and faculty opportunities to explore new ideas,
                    showcase talents, and build lasting connections. Each event
                    at Sitare University is designed to enrich the learning
                    experience, promote innovation, and celebrate achievements.
                    Stay updated with our upcoming events and take a look back
                    at the memorable moments that shaped our vibrant campus
                    life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Upcoming Activities */}
      <section className="ca-activities">
        <div className="container">
          <h1 className="global-heading mb-4">Upcoming Events</h1>
          <p className="global-bodytext">
            Discover what's happening next at Sitare University! Our Upcoming
            Events section highlights exciting activities, workshops, and
            competitions on the horizon. From tech hackathons to cultural
            festivals and guest lectures, there's always something to look
            forward to. Get ready to dive into campus life!
          </p>

          <ul className="card-upcoming--list mt-5">
            {EVENTS_DATA &&
              EVENTS_DATA.length > 0 &&
              EVENTS_DATA.map((item, index) => {
                // if (item?.status == "upcoming") {
                return (
                  <li className="card-upcoming--item">
                    <div className="card-upcoming-content">
                      <div className="card-upcoming-contentimg">
                      <img src={item?.image} alt="profile-image" draggable="false"/>
                      </div>

                      <div>
                        <h3 className="global-subheading global-fw600 mb-3">
                          {item?.name}
                        </h3>
                        <p className="global-bodytext mb-2">
                          Event Date: {item?.date}
                        </p>
                        <p className="global-bodytext global-lineclamp mb-2">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                    <button onClick={() => navigate(`/upcoming-activities`)} className="global-orangeBtn flex-shrink-0">
                      know more
                    </button>
                  </li>
                );
                // }
              })}
          </ul>
        </div>
      </section>

      {/* Section: Past Activities */}
      <section className="ca-activities">
        <div className="container">
          <h1 className="global-heading mb-4">past activities</h1>
          <p className="global-bodytext">
            Explore the memorable events that have shaped Sitare University. Our
            Past Events section features highlights and achievements from
            previous activities, including hackathons, cultural fests, and
            workshops. Relive the moments that inspired our community.
          </p>

          <ul className="card-upcoming--list mt-5">
            {EVENTS_DATA &&
              EVENTS_DATA.length > 0 &&
              EVENTS_DATA.map((item, index) => {
                // if (item?.status === "completed") {
                return (
                  <li className="card-upcoming--item card-past">
                    <div className="card-upcoming-content">
                      <div className="card-upcoming-contentimg">
                        <img src={item?.image} draggable="false" alt="image"/>
                      </div>
                     
                      <div>
                        <h3 className="global-subheading global-fw600 mb-3">
                          {item?.name}
                        </h3>
                        <p className="global-bodytext mb-2">
                          Event Date: {item?.date}
                        </p>
                        <p className="global-bodytext global-lineclamp mb-2">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                    <button onClick={() => navigate("/past-activities")} className="global-orangeBtn flex-shrink-0">
                      View activities
                    </button>
                  </li>
                );
                // }
              })}
          </ul>
        </div>
      </section>

      <section className="container py-lg-5 mb-5 se-cards">
        <MenuCards
          data={EVENTS_MENU_DATA}
          handleMenuClick={handleMenuClick}
          themeColor="sh-menuCard--orange-theme"
        />
      </section>
    </>
  );
};

export default UniversityEvent;
