import React from "react";
import './style.scss';
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import alumniBenefits from "../../assets/images/alumniBenefits.png";
import { useNavigate } from "react-router-dom";

const AlumniBenefits = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Section:Banner */}
      <section>
        <SecondaryBanner
          imageUrl={alumniBenefits}
          title="About Us - Alumni - Benefits"
          desc="Benefits of being an Alumni"
          themeColor="sh-contentimgsplit--green500-theme"
        />
      </section>

      {/* Section:About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="global-subheading">
                Being an alumni of Sitare University comes with a wealth of
                benefits designed to support your professional growth, personal
                development, and continuous connection with the Sitare
                community. Stay engaged, stay connected, and continue to thrive
                as part of the Sitare University family.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section:topics */}
      <section className="container">
            <div className="row">
                <div className="col-12">
                    <h4 className="global-subheading global-fw600">Lifelong Learning and Career Support</h4>
                    <ul className="al-topics">
                        <li>
                            <p className="global-bodytext">Career Services: Access exclusive career counseling, job postings, and professional development resources to help you advance in your career.</p>
                        </li>
                        <li>
                            <p className="global-bodytext">Library Access: Continue to benefit from our extensive library resources and online databases to support your ongoing learning and research needs.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h4 className="global-subheading global-fw600">Networking Opportunities</h4>
                    <ul className="al-topics">
                        <li>
                            <p className="global-bodytext">Alumni Portal: Connect with fellow alumni, current students, and faculty through our online alumni portal, fostering valuable professional and personal relationships.</p>
                        </li>
                        <li>
                            <p className="global-bodytext">Events and Reunions: Attend annual alumni events, reunions, and networking sessions to reconnect with old friends and make new connections.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h4 className="global-subheading global-fw600">Exclusive Discounts and Offers</h4>
                    <ul className="al-topics">
                        <li>
                            <p className="global-bodytext">Alumni Discounts: Enjoy special discounts on further education programs, university events, and Sitare University merchandise.</p>
                        </li>
                        <li>
                            <p className="global-bodytext">Educational Resources: Gain access to exclusive educational materials and resources to support your continuous learning journey.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h4 className="global-subheading global-fw600">Community Engagement</h4>
                    <ul className="al-topics">
                        <li>
                            <p className="global-bodytext">Volunteer Opportunities: Engage in volunteer activities and community service initiatives organized by the alumni association, making a positive impact on society.</p>
                        </li>
                        <li>
                            <p className="global-bodytext">
                            Giving Back: Contribute to the Sitare Foundation to support scholarships, infrastructure development, and other university initiatives, helping future generations of students.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <h4 className="global-subheading global-fw600">Leadership and Involvement</h4>
                    <ul className="al-topics">
                        <li>
                            <p className="global-bodytext">Alumni Association Membership: Join the Sitare University Alumni Association to play an active role in shaping the future of our alumni community.</p>
                        </li>
                        <li>
                            <p className="global-bodytext">
                            Leadership Roles: Take on leadership positions within the alumni association and contribute to its growth and development.</p>
                        </li>
                    </ul>
                </div>
            </div>
      </section>
    </>
  );
};

export default AlumniBenefits;
