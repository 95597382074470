import React from "react";
import { Link } from "react-router-dom";
import MenuCards from '../../components/sections/MenuCards';
import { useNavigate } from 'react-router-dom';
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import PedagogyImg from "../../assets/images/pedagogy_img.png";
import "./style.scss";
import "../../assets/styles/styles.scss";
import { MENU_CARDS_DATA, ACADEMIC_MENU_DATA } from '../../constants';
import CURRICULUM_DATA from '../../dummyData/CurriculumData.json';
import BannerImg from "../../assets/images/curriculum_banner.png";
import SecondImg from "../../assets/images/students/students-class-grayscale.png";


const Curriculum = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if (route.includes("http:") || route.includes("https:")) {
      window.open(route, "_blank")
    } else {
      navigate(`/${route}`);
    }
  }
  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="Academic Life - Our Courses"
          desc="Future-Focused Curriculum"
          themeColor="sh-contentimgsplit--yellow-theme"
        />
      </section>

      {/* Section: Transforming Lives */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">
                    Transformative Curriculum for the Future of Computer Science
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    The newly established Sitare University has unveiled an innovative curriculum designed for the evolving demands of the 21st century. This curriculum integrates foundational Computer Science knowledge with cutting-edge fields like Artificial Intelligence, Data Science, Web Development Frameworks, and Information Retrieval. Students will engage in project-based learning, collaborative research, and internships starting from their first year. Our program emphasizes critical thinking, communication skills, and ethical leadership, preparing graduates for diverse, high-paying careers. Additionally, Sitare University offers flexible learning paths, personalized mentorship, and a robust support system to foster both academic and personal growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: University Curriculum */}
      <section className="sc-curriculum">
        <div className="container">
          <div className="sc-curriculum--header">
            <h3 className="sc-curriculum--header-text">sitare university curriculum</h3>
            {/* <button className="sc-curriculum--header-btn">download curriculum</button> */}
          </div>
          <div className="sc-curriculum--table">
            <table className="sc-curriculum--tablewrapper">
              <thead>
                <tr>
                  <th></th>
                  <th className="sc-curriculum--tableheading">MATHEMATICS</th>
                  <th className="sc-curriculum--tableheading">COMMUNICATION<br/>& ETHICS</th>
                  <th className="sc-curriculum--tableheading">PROGRAMMING</th>
                  <th className="sc-curriculum--tableheading">SYSTEMS/ SECURITY</th>
                  <th className="sc-curriculum--tableheading">AI/ ML</th>
                  <th className="sc-curriculum--tableheading">PROJECT</th>
                </tr>
              </thead>
              <tbody>
                {CURRICULUM_DATA && CURRICULUM_DATA.length > 0 && CURRICULUM_DATA.map((item, index) => {
                  const {semester, totalCredits, subjects} = item

                  return(
                    <tr key={index}>
                      <td className="sc-curriculum--table-row-heading">
                        <p className="sc-curriculum--table-row-heading--semester">{semester}</p>
                        <p className="sc-curriculum--table-row-heading--credits">{totalCredits} Credits</p>
                      </td>
                      {subjects && subjects.length > 0 && subjects.map((sub, subInd) => (
                          <td key={subInd}>
                            {sub?.name ?
                            <>
                              <b className="text-uppercase">{`${sub?.code}`} {sub?.credits != 0 && `(${sub?.credits})`}</b>
                              <p className="mt-2">{sub?.name}</p>
                            </> 
                            :
                            <p>-</p>
                            }
                            
                          </td>
                      ))}
                    </tr>
                  ) 
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* Section: Our Philosophy */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading pe-md-3">
                    Our Philosophy:<br /> Bridging Foundational Knowledge with Future-Ready Skills
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    The Computer Science curriculum at Sitare University emphasizes both foundational knowledge and emerging technologies. It equips students with a deep understanding of core principles like Data Structures and Programming Methodologies while immersing them in areas such as Artificial Intelligence, Information Retrieval, and Data Analysis. Sitare University aims to produce graduates who are technically proficient, ethically conscious, and socially responsible. The curriculum fosters innovation, critical thinking, and problem-solving skills, preparing students to drive technological advancements and address complex global challenges. Emphasizing collaboration, it ensures students can apply computer science principles across various fields, from healthcare to environmental science.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Pedagogy Banner */}
      <section className="sc-pedagogybanner">
        <div className="container">
          <img src={SecondImg} alt="Pedagogy Image" draggable="false" className="sc-pedagogybanner--image" />
        </div>
      </section>

      {/* Section: Pedagogy */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading pe-md-3">
                    Pedagogy: Integrating Core Knowledge with Emerging Technologies
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    The Computer Science curriculum at Sitare University emphasizes both foundational knowledge and emerging technologies. It equips students with a deep understanding of core principles like Data Structures and Programming Methodologies while immersing them in areas such as Artificial Intelligence, Information Retrieval, and Data Analysis. Sitare University aims to produce graduates who are technically proficient, ethically conscious, and socially responsible. The curriculum fosters innovation, critical thinking, and problem-solving skills, preparing students to drive technological advancements and address complex global challenges. Emphasizing collaboration, it ensures students can apply computer science principles across various fields, from healthcare to environmental science.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* TODO(akshi): Add curriculum related menu cards here */}

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard">
      <div className='container'>
        <div className="row">
          <div className="col-12">
          <MenuCards data={ACADEMIC_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--yellow-theme" />
          </div>
        </div>
      </div>
      </section>

    </>
  );
};

export default Curriculum;
