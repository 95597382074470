import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import EsportsBanner from "../../assets/images/EsportsBanner.png";
import MenuCards from "../../components/sections/MenuCards";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";
import auditorium1 from "../../assets/images/auditorium/a1.png";
import auditorium2 from "../../assets/images/auditorium/a2.png";
import auditorium3 from "../../assets/images/auditorium/a3.png";
import auditorium4 from "../../assets/images/auditorium/a4.png";
import auditorium5 from "../../assets/images/auditorium/a5.png";
import auditorium6 from "../../assets/images/auditorium/a6.png";
import UPCOMING_EVENTS from '../../dummyData/UpcomingSportsEvents.json'

const EventSportsPage = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    navigate(`/${route}`);
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={EsportsBanner}
          title="University Life - Events & Activities - Sports"
          desc="Unleash Your Potential"
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Indoor and Outdoor */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
              Indoor and Outdoor Sports
              </h1>
              <p className="global-bodytext">
              At Sitare University, we believe that sports are essential for developing teamwork, leadership, and a healthy lifestyle. Our sports programs are designed to help students stay active, challenge themselves, and enjoy friendly competition.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Culture Cards */}
      <section className="ei-culture">
        <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="ei-culture--card">
              <h3 className="global-heading text-white mb-3 global-fw600">Indoor</h3>
              <ul>
                <li>Badminton</li>
                <li>Table Tennis</li>
                <li>Basketball</li>
                <li>Chess</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 col-12">
            <div className="ei-culture--card">
              <h3 className="global-heading text-white mb-3 global-fw600">Outdoor</h3>
              <ul>
                <li>Football</li>
                <li>Cricket</li>
                <li>Athletics</li>
                <li>Volleyball</li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </section>

      {/* Section: Why Join */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading mb-4">Why Join a Club?</h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext mb-5">
                  Joining a club is the perfect way to meet new people, learn outside of the classroom, and take on leadership roles. Whether you’re passionate about art, coding, or making a difference in the community, there’s a club for you.
                  </p>
                  <div>
                    <button type="button" class="global-orangeBtn px-5">
                      join now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

       {/* Section: Upcoming Sport Event */}
       <section className="global-events">
            <div className="container">
            <div className="row justify-content-md-center">
              <h1 className="global-heading mb-4">
                Upcoming Sport Event
              </h1>
              
              <ul>
                {UPCOMING_EVENTS && UPCOMING_EVENTS.length > 0 && UPCOMING_EVENTS.map((item, index) => (
                  <li className='global-events--list'>
                    <h3 className='global-events--listdate'>{item?.date}</h3>
                    {item?.programs && item?.programs.length > 0 && item?.programs.map((program, ind) => (
                      <div className='global-events--listcontent'>
                        <p className='global-events--listtitle'>{program?.title}</p>
                        <p className='global-bodytext'>{program?.desc}</p>
                      </div>
                    ))}
                  </li>
                ))}
              </ul>
            </div>
          </div>
      </section>

      {/* Section: Gallery */}
      <section className="container">
        <div className="row">
          <div className="col-12">
          <h1 className="global-heading mb-4">Gallery</h1>
          <div className="ei-imgcards">
              <img src={auditorium1} alt="images" draggable="false" />
              <img src={auditorium2} alt="images" draggable="false" />
              <img src={auditorium3} alt="images" draggable="false" />
              <img src={auditorium4} alt="images" draggable="false" />
              <img src={auditorium5} alt="images" draggable="false" />
              <img src={auditorium6} alt="images" draggable="false" />
            </div>
          </div>
        </div>
      </section>


      {/* Section:Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={EVENTS_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventSportsPage;
