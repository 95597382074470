import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import clubsBanner from "../../assets/images/clubs/clubsBanner.png";
import MenuCards from "../../components/sections/MenuCards";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";
import SCHEDULE_PROGRAME from "../../dummyData/SchedulePrograme.json";
import { Carousel } from "primereact/carousel";
import INSIGHTS_DATA from '../../dummyData/InternshipInsights.json';

const CAROUSEL_DATA = [
  {
    id: 1,
    title: "Tech & Innovation Club",
    desc: "Coding Club, Robotics Club, AI Enthusiasts.",
    image: "https://images.pexels.com/photos/1065084/pexels-photo-1065084.jpeg"
  },
  {
    id: 2,
    title: "Cultural & Creative:",
    desc: "Drama Society, Music Club, Photography Club.",
    image: "https://images.pexels.com/photos/227294/pexels-photo-227294.jpeg"
  },
  {
    id: 3,
    title: "Social & Community:",
    desc: "Environment Club, Debate Society, Social Impact Group.",
    image: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg"
  },
  {
    id: 4,
    title: "Tech & Innovation Club",
    desc: "Coding Club, Robotics Club, AI Enthusiasts.",
    image: "https://images.pexels.com/photos/5234265/pexels-photo-5234265.jpeg"
  },
  {
    id: 5,
    title: "Cultural & Creative:",
    desc: "Drama Society, Music Club, Photography Club.",
    image: "https://images.pexels.com/photos/5234265/pexels-photo-5234265.jpeg"
  },
  {
    id: 6,
    title: "Social & Community:",
    desc: "Environment Club, Debate Society, Social Impact Group.",
    image: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg"
  }
]

const ClubsPage = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    navigate(`/${route}`);
  };


  const responsiveOptions = [
    {
      breakpoint: '3600px',
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: '1200px',
      numVisible: 3,
      numScroll: 1,
    },
    {
        breakpoint: '992px',
        numVisible: 2,
        numScroll: 1,
    },
    {
        breakpoint: '481px',
        numVisible: 1,
        numScroll: 1,
    }
  ];


  const productTemplate = (item) => {
    return (
      <div className="ei-connections--carouselitem m-2">
        <img
          className="ei-connections--carouselitem-img" alt="Profile Pic"
          src={item?.image}
        />
        <div className="ei-connections--carouselitem-content">
          <h3 className="global-subheading global-fw600 mb-3">{item?.title}</h3>
          <p className="ei-connections--carouselitem--desc">{item?.desc}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={clubsBanner}
          title="University Life - Events & Activities - Clubs"
          desc="Where Passion Meets Purpose."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Explore Passion */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
                Explore Your Passion, Build Lifelong Connections
              </h1>
              <p className="global-bodytext">
                Sitare University offers a diverse range of student clubs that
                cater to a variety of interests, from technology and
                entrepreneurship to arts and culture. These clubs provide
                students with opportunities to pursue their passions, develop
                new skills, and collaborate with like-minded individuals.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Internship Insights */}
      <section className="ei-connections">
        <div className="pt-5">
          <Carousel
            value={CAROUSEL_DATA}
            numVisible={4}
            numScroll={1}
            responsiveOptions={responsiveOptions}
            className="custom-carousel in-insights--internship-carousel"
            circular={false}
            showIndicators={false}
            autoplayInterval={0}
            itemTemplate={productTemplate}
            prevIcon={<i className="fa-solid fa-angle-left"></i>}
            nextIcon={<i className="fa-solid fa-angle-right"></i>}
            indicatorsContentClassName="gdhshds"
          />
        </div>
      </section>

      {/* Section: Why Join */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading mb-4">Why Join a Club?</h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext mb-5">
                    Joining a club is the perfect way to meet new people, learn
                    outside of the classroom, and take on leadership roles.
                    Whether you’re passionate about art, coding, or making a
                    difference in the community, there’s a club for you.
                  </p>
                  <div>
                    <button type="button" class="global-orangeBtn px-5">
                      join now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Schedule Programe */}
      <section className="global-events pb-0">
        <div className="container">
          <div className="row justify-content-md-center">
            <h1 className="global-heading mb-4">Upcoming Club Meetings</h1>

            <ul>
              {SCHEDULE_PROGRAME &&
                SCHEDULE_PROGRAME.length > 0 &&
                SCHEDULE_PROGRAME.map((item, index) => (
                  <li className="global-events--list">
                    <h3 className="global-events--listdate">{item?.date}</h3>
                    {item?.programs &&
                      item?.programs.length > 0 &&
                      item?.programs.map((program, ind) => (
                        <div className="global-events--listcontent">
                          <h2 className="global-events--listtime">
                            {program?.fromTime} - {program?.toTime}
                          </h2>
                          <p className="global-events--listlocation">
                            Location: {program?.location}
                          </p>
                          <p className="global-events--listtitle">
                            {program?.title}
                          </p>
                          <p className="global-bodytext">
                            {program?.desc}
                          </p>
                        </div>
                      ))}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </section>

      {/* Section:Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={EVENTS_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClubsPage;
