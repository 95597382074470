import React, {useLayoutEffect} from 'react'
import Header from '../header';
import Footer from '../footer';
import { useLocation } from 'react-router-dom';

const MainLayout = ({children}) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  return (
    <div>
        <Header />
        <main className='global-bodywrapper'>
            {children}
        </main>
        <Footer />
    </div>
  )
}

export default MainLayout