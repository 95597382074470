import React from 'react';
import './style.scss';
import ADVISORS_DATA from '../../dummyData/AdvisorsData.json'
import VISITING_FACULTY from '../../dummyData/VisitingFaculty.json'
import { MENU_CARDS_DATA } from '../../constants';
import Arrow from '../../assets/images/arrow_orange.png';
import { Link, useNavigate } from 'react-router-dom';
import MenuCards from '../../components/sections/MenuCards';

const OurPeople = () => {

  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if(route.includes("http:") || route.includes("https:")){
      window.open(route, "_blank")
    }else{
      navigate(`/${route}`);
    }
  }


  return (
    <>
      {/* Section: Our people */}
      <section className="sh-advisors">
        <div className="container">
            <h2 className='sh-advisors--heading'>Our People</h2>
            <div className="sh-advisors--grid">
              {ADVISORS_DATA && ADVISORS_DATA.length > 0 && ADVISORS_DATA.map((item, index) => (
                <div key={index} className='d-flex flex-column align-items-center d-lg-block'>
                  <img className='sh-advisors--profilepic' src={item?.profilePic} alt='Advisor Pic' />
                 
                  <div className='sh-advisors--info'>
                    <p className='sh-advisors--info--text'>{item?.name}</p>
                    <div className='sh-advisors--info--divider'></div>
                    <p className='sh-advisors--info--text'>{item?.designation}</p>
                  </div>
                  {/* <div className='sh-advisors--icon'>
                    <img src={Arrow} alt='Arrow' />
                  </div> */}
                </div>
              ))}
            </div>
        </div>
    </section>

      {/* Section: Visting Faculty */}
      <section className="sh-advisors">
        <div className="container">
            <h2 className='sh-advisors--heading'>Visting Faculty</h2>
            <div className="sh-advisors--grid">
              {VISITING_FACULTY && VISITING_FACULTY.length > 0 && VISITING_FACULTY.map((item, index) => (
                <div key={index} className='d-flex flex-column align-items-center d-lg-block'>
                  <img className='sh-advisors--profilepic' src={item?.profilePic} alt='Advisor Pic' />
                 
                  <div className='sh-advisors--info'>
                    <p className='sh-advisors--info--text'>{item?.name}</p>
                    <div className='sh-advisors--info--divider'></div>
                    <p className='sh-advisors--info--text'>{item?.designation}</p>
                  </div>
                  {/* <div className='sh-advisors--icon'>
                    <img src={Arrow} alt='Arrow' />
                  </div> */}
                </div>
              ))}
            </div>
        </div>
    </section>

    {/* Section: View Also */}
    {/* <section className="sh-viewalso">
      <div className="container">
        <p className='sh-viewalso--heading'>view also</p>
        <ul className='sh-viewalso--list'>
          <li className='sh-viewalso--list-item'>
            <Link to={"/about/our-people"}>Faculty</Link>
          </li>
          <li className='sh-viewalso--list-item'>
            <Link to={"/about/our-people"}>Administration</Link>
          </li>
          <li className='sh-viewalso--list-item'>
            <Link to={"/about/our-people"}>Staff</Link>
          </li>
          <li className='sh-viewalso--list-item'>
            <Link to={"/about/our-people"}>Students</Link>
          </li>
        </ul>
      </div>
    </section> */}

    {/* <section className="global-bgmenucard pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <MenuCards data={MENU_CARDS_DATA} handleMenuClick={handleMenuClick} />
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}

export default OurPeople;