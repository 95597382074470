import React from 'react';
import InspiringStoriesDetail from './InspiringStoriesDetail';

const InspiringStoriesComp = ({data}) => {
  return (
    <>
      
            <li className='global-newscard--list global-newscard--listtwo'>
                <div className='global-newscard--listimage'>
                    <img src={data?.image} alt="story image" draggable="false" className='rounded-0'/>
                </div>
                <div className='global-newscard--listcontent'>
                    <h3 className="global-newscard--listtitle">{data?.studentName}</h3>
                    <h3 className="global-newscard--listsubtitle">{data?.studentBatch}</h3>
                    <p className="global-bodytext global-newscard--listdesc">
                        {data?.desc}
                    </p>

                    <button className='global-newscard--listbtn' data-bs-toggle="offcanvas" data-bs-target={`#InspiringStoriesDetail${data?.id}`} aria-controls={`InspiringStoriesDetail${data?.id}`} >Read Full Article</button>
                </div>
            </li>
      
        <InspiringStoriesDetail item={data}  />
    </>
  )
}

export default InspiringStoriesComp