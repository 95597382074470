import React, { useEffect } from 'react';
import OwlCarousel from "react-owl-carousel";
import INDUSTRY_CONNECTIONS from "../../dummyData/IndustryConnections.json";
import '../../assets/styles/_common_owlCarousel.scss';

const CollageCarousel = ({data}) => {

    return (
        <>
            <OwlCarousel
                id='cc_carousel'
                items={1}
                className="cc-collage--owl-theme"
                loop
                nav
                dotClass="cc-collage--carousel-dot-wrapper--carousel-dot"
                dotsClass="cc-collage--carousel-dot-wrapper"
                navIcon={["PREV", "NEXT"]}
                margin={12}
                responsive={{
                    0: {
                        items: 1,
                    },
                    576: {
                        items: 2,
                        margin: 20,
                    }
                }}
            >
                        
                {data &&
                    data.length > 0 &&
                    data.map((imageItem, imgInd) => (
                    <div
                        key={imgInd}
                        className="cc-collage--owl-theme--griditem"
                    >
                        <img className="img" src={imageItem} alt="image" draggable="false" />
                    </div>
                ))}
            </OwlCarousel>
            <div className='cc-collage--list' id='cc_grid'>
                {data &&
                    data.length > 0 &&
                    data.map((imageItem, imgInd) => (
                        <div
                            key={imgInd}
                            className="cc-collage--listitem"
                        >
                            <img className="img" src={imageItem} alt="image" draggable="false" />
                        </div>
                    ))}
            </div>
        </>
    )
}

export default CollageCarousel