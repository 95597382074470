import React, {useState, useEffect} from 'react';
import './style.scss';
import BannerImg from "../../assets/images/news_update_banner.png";
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import NEWS_UPDATES from '../../dummyData/NewsAndUpdate.json';

const NewsAndUpdates = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currPageNumber, setCurrPageNumber] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);

  useEffect(() => {
    let pages = Math.ceil(NEWS_UPDATES.length/itemsPerPage); 
    setTotalPages(pages);
    trimData(1, itemsPerPage);
  }, [NEWS_UPDATES]);

  const trimData = (page, itemsPerPage) => {
    const fromInd = (page - 1) * itemsPerPage;
    const toInd = fromInd + itemsPerPage;
    const previousData = JSON.parse(JSON.stringify(NEWS_UPDATES));
    const trimData = previousData.slice(fromInd, toInd);
    setCurrentPageData(trimData);
  };

  const handlePageNoClick = (currPage) => {
    trimData(currPage, itemsPerPage)
    setCurrPageNumber(currPage);
  }

  const handlePrevNext = (type, currPage) => {
    if(type == "Prev"){
      if(currPage > 1) {
        trimData(currPage - 1, itemsPerPage)
        setCurrPageNumber(currPage - 1);
      }
    }else{
      if(currPage < totalPages) {
        trimData(currPage + 1, itemsPerPage)
        setCurrPageNumber(currPage + 1);
      }
    }
  }

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
            imageUrl={BannerImg}
            title="news & media"
            desc="In the Spotlight: Sitare University in News & Media."
            themeColor="sh-contentimgsplit--red300-theme"
            textColor="text-white"
        />
      </section>

      {/* Section: Latest Stories */}
      <section className='global-newscard global-bglpink'>
        <div className='container'>
          <h1 className="global-heading">
            Stay updated with the latest stories, achievements, and media coverage featuring Sitare University’s journey of academic excellence and innovation.
          </h1>

          <ul className='global-newscard--wrapper'>
            {currentPageData && currentPageData.length > 0 && currentPageData.map((item, index) => (
              <li key={index} className='global-newscard--list'>
                <div className='global-newscard--listimage'>
                  <img src={item?.image} alt="story image"/>
                </div>
                <div className='global-newscard--listcontent'>
                  <h3 className="global-newscard--listtitle">{item?.title}</h3>
                  <p className="global-bodytext global-newscard--listdesc">
                    {item?.desc}
                  </p>
                  <button className='global-newscard--listbtn'>Read Full Article</button>
                </div>
              </li>
            ))}
          </ul>

          <div className='global-pagination d-flex justify-content-center my-5'>
            <button className='me-3' onClick={() => handlePrevNext("Prev", currPageNumber)}>Previous</button>
            {totalPages && totalPages > 0 && [...Array(totalPages)].map((item, index) => (
              <div className={`global-pagination--number ${(currPageNumber == (index+1)) && "active"}`} onClick={() => handlePageNoClick(index+1)}>
                <p>{index+1}</p>
              </div>
            ))}
            <button className='ms-3' onClick={() => handlePrevNext("Next", currPageNumber)}>Next</button>
          </div>
        </div>
      </section>
    </>
  )
}

export default NewsAndUpdates;