import React from 'react'

const GrowthSteps = ({bgTheme}) => {
  return (
    <div className='rw-growth'>
        <div className={`rw-growth--step ${bgTheme}`}>
            <h3 className='rw-growth--step--title'>year 1</h3>
            <h3 className='rw-growth--step--title'>internship stipend</h3>
            <p className='rw-growth--step--desc'>Average stipend: ₹15000 - ₹20000 per month</p>
        </div>
        <div className={`rw-growth--step ${bgTheme}`}>
            <h3 className='rw-growth--step--title'>year 2</h3>
            <h3 className='rw-growth--step--title'>internship stipend</h3>
            <p className='rw-growth--step--desc'>Average stipend: ₹20000 - ₹30000 per month</p>
        </div>
        <div className={`rw-growth--step ${bgTheme}`}>
            <h3 className='rw-growth--step--title'>year 3</h3>
            <h3 className='rw-growth--step--title'>internship stipend</h3>
            <p className='rw-growth--step--desc'>Average stipend: ₹30000 - ₹40000 per month</p>
        </div>
        <div className={`rw-growth--step ${bgTheme}`}>
            <h3 className='rw-growth--step--title'>year 4</h3>
            <h3 className='rw-growth--step--title'>internship stipend</h3>
            <p className='rw-growth--step--desc'>Average stipend: ₹30000 - ₹40000 per month</p>
        </div>
    </div>
  )
}

export default GrowthSteps;