import React from "react";
import { MegaMenu } from "primereact/megamenu";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { getMenuItems } from "./MegaMenuItems";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

let MenuItemss = [
  // Curriculum Menu Items
  {
    label: "Curriculum",
    icon: "",
    items: [
      {
        label: "Curriculum",
        command: () => {},
      },
      {
        label: "Courses",
        icon: "",
        items: [
          { label: "Project" },
          { label: "Student project" },
          { label: "Sitare github" },
          { label: "Sitare hugging face" },
          { label: "Faculty projects" },
        ],
      },
      {
        label: "Admissions",
        icon: "",
        items: [
          { label: "How to apply" },
          { label: "Requirements" },
          { label: "Placements" },
          { label: "Required Document" },
          { label: "Contact Us" },
          { label: "Requirements" },
        ],
      },
    ],
    command: () => {
      // Callback to run
    },
  },

  //   University Menu Items
  {
    label: "University life",
    icon: "",
    items: [
      { label: "University life" },
      {
        label: "Academic Life",
        icon: "",
        items: [
          { label: "Career Services" },
          { label: "Library" },
          { label: "Campus Map" },
          { label: "Academic Calendar" },
          { label: "Student Support" },
        ],
      },
      {
        label: "Student Life",
        icon: "",
        items: [
          { label: "Sports" },
          { label: "Clubs" },
          { label: "Events" },
          { label: "Fests" },
        ],
      },
    ],
    command: () => {
      // Callback to run
    },
  },

  //   About Us Menu Item
  {
    label: "About Us",
    icon: "",
    items: [
      { label: "About Us", icon: "" },
      [
        {
          label: "Who We Are",
          icon: "",
          items: [
            { label: "Our Past" },
            { label: "Our Present" },
            { label: "Our Future" },
          ],
        },
        {
          label: "Alumni",
          icon: "",
          items: [
            { label: "Stay Connected" },
            { label: "Alumni Testimonial" },
            { label: "Archived Gallery" },
            { label: "Events Reunion" },
          ],
        },
      ],
      [
        {
          label: "Our People",
          icon: "",
          items: [
            { label: "Advisors" },
            { label: "Faculties" },
            { label: "Administration" },
            { label: "Students" },
            { label: "Staffs" },
          ],
        },
        {
          label: "Work With Us",
          icon: "",
          items: [
            { label: "Academic Vacancies" },
            { label: "Administrative Vacancies" },
          ],
        },
      ],
      [
        {
          label: "Our Campus",
          icon: "",
          items: [{ label: "Current Campus" }, { label: "Future Campus" }],
        },
        { label: "Gallery", icon: "", items: [{ label: "Gallery" }] },
      ],
    ],
    command: () => {
      // Callback to run
    },
  },

  //   Contact Us Menu Item
  {
    label: "Contact Us",
    icon: "",
    items: [{ label: "Contact Us" }],
    command: () => {
      // Callback to run
    },
  },

  //   Search Section
  {
    label: "Apply",
    icon: "",
  },

  // Apply Now Section
];

const Header = () => {
  const navigate = useNavigate();
  const menuItems = getMenuItems(navigate);

  return (
    <>
      {/* Section: Upper Header */}
      <section className="su-header--upper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="su-header--upperlist">
                <li>
                  <a href="#!" className="active">
                    +91 7829758672
                  </a>
                </li>
                <li>
                  <Link to={"/news-updates"}>News & Updates</Link>
                </li>
                <li>
                  <Link to={'/events-activities'}>Events</Link>
                </li>
                <li>
                  <Link to={'/blogs'}>Blog</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Section: Upper Header End */}

      {/* Section: Header Menu */}
      <section className="su-header--menu ">
        <div className="container px-md-0 px-md-3">
          <div className="row align-items-center">
            <div className="col-md-2 col-6">
            <div className="logo_header">
                <Link to={"/"}>
                  <img src={Logo} alt="logo" draggable="false" />
                </Link>
              </div>
            </div>
            <div className="col-md-10 col-6">
           
              <MegaMenu
                // model={MenuItems}
                model={menuItems}
                breakpoint="992px"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Section: Header Menu End*/}
    </>
  );
};

export default Header;
