import React from 'react'
import './style.scss';
import UniversityImg from "../../assets/images/university.png";
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import MenuCards from '../../components/sections/MenuCards';
import { UNIVERSITY_MENU_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const UniversityLife = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        if (route.includes("http:") || route.includes("https:")) {
            window.open(route, "_blank")
        } else {
            navigate(`/${route}`);
        }
    }

    return (
        <>
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl={UniversityImg}
                    title="University Life"
                    desc="Discover a Vibrant Community Beyond the Classroom."
                    themeColor="sh-contentimgsplit--orange-theme"
                />
            </section>

            {/* Section: About Text */}
            <section className="global-aboutwrapper" style={{ marginBottom: '50px' }}>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-12">
                            <h1 className="global-heading mb-4">
                                Endless Ways to Expand The Horizons
                            </h1>
                            <p className="global-bodytext">
                                Life at Sitare University offers a unique and intimate experience, fostering close-knit communities where students and faculty often know each other by name. With smaller class sizes, students benefit from personalized attention, meaningful discussions, and strong mentorship opportunities. Campus events, clubs, and activities are typically more accessible, allowing everyone to actively participate and form lasting friendships. The supportive environment encourages collaboration and growth, both academically and socially, while the manageable campus size creates a sense of belonging. Overall, life at Sitare University combines academic rigor with a warm, welcoming atmosphere.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* TODO(akshi): Add University Life related menu cards here */}

            {/* <section className='container py-lg-5 mb-5 se-cards'>
                <MenuCards data={UNIVERSITY_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--orange-theme" />
            </section> */}
        </>
    )
}

export default UniversityLife;