import React from 'react'
import './style.scss';
import FacilityImg from "../../assets/images/facility.png";
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import MenuCards from '../../components/sections/MenuCards';
import { FACILITIES_MENU_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const Facilities = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        navigate(`/${route}`);
    }

    return (
        <>
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl= {FacilityImg} 
                    title= "University Life - Facilities"
                    desc= "Campus Experience: Where Learning Meets Living."
                    themeColor="sh-contentimgsplit--orange-theme"
                />
            </section>
            <section className='container py-lg-5 mb-5 se-cards'>
                <MenuCards data={FACILITIES_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--orange-theme" />
            </section>
        </>
    )
}

export default Facilities;