import React from 'react';
import './style.scss';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import jobGrad from "../../assets/images/jobGrad.png";
import OwlCarousel from "react-owl-carousel";
import CarouselBanner from "../../../src/assets/images/banner1.jpg";
import INDUSTRY_CONNECTIONS from '../../dummyData/IndustryConnections.json';
import PARTNERS_REVIEW_DATA from '../../dummyData/PartnersReview.json';
import { Carousel } from "primereact/carousel";
import { Link } from "react-router-dom";
import CollageCarousel from '../../components/sections/CollageCarousel';


const JobAfterGraduation = () => {

    const responsiveOptions = [
        {
          breakpoint: '3600px',
          numVisible: 4,
          numScroll: 1,
        },
        {
          breakpoint: '1200px',
          numVisible: 3,
          numScroll: 1,
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1,
        }
    ];

    const partnersReviewTemplate = (item) => {
        return (
          <div className="in-insights--carouselitem in-partners--carousel-item mx-md-2">
            <img
              className="in-insights--carouselitem--imgfull" alt="Profile Pic"
              src={item?.profilePic} draggable='false'
            />
            <h3 className="global-subheading global-fw600 mb-4">{item?.name}</h3>
            <p className="in-insights--carouselitem--desc">{item?.description}</p>
            <button className="in-insights--carouselitem--btn">
              Read More
            </button>
          </div>
        );
    };

    const studentsExpTemplate = (item) => {
        return (
          <div className="in-insights--carouselitem in-partners--carousel-item mx-sm-2">
            <img
                className="in-completed--griditem--img"
                src={item?.profilePic}
                alt="profile-pic" draggable="false"
            />
            <h3 className="global-subheading global-fw600 mt-2">{item?.name}</h3>
            <p className="global-bodytext mb-3">Student Batch</p>
            <img
              className="in-insights--carouselitem--imgfull" alt="Profile Pic"
              src={item?.profilePic} draggable='false'
            />
            <p className="in-insights--carouselitem--desc">{item?.description}</p>
            <button className="in-insights--carouselitem--btn">
              Read More
            </button>
          </div>
        );
    };

  return (
    <>
        {/* Section: Banner */}
        <section className='mb-5'>
            <SecondaryBanner
                imageUrl= {jobGrad} 
                title= "Placements - Job after graduation"
                desc= "Career Success Beyond Graduation."
                themeColor="sh-contentimgsplit--red700-theme"
            />
        </section>

        {/* Section: Building Real World */}
         <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                        Launching Careers 
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <h4 className='global-subheading mb-4'>Your Path Beyond Graduation</h4>
                        <p className="global-bodytext">
                        Sitare University is committed to helping our graduates launch successful careers. Through career counseling, industry connections, and a strong network of alumni, we equip students with the tools they need to secure rewarding positions in their chosen fields.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        {/* Section: Salaries */}
        <section className="container">
            <div className="cp-salaries">
                <div className="row justify-content-md-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 col-12">
                                <div className='cp-salaries--wrapper mb-md-0 mb-3'>
                                    <span className='cp-salaries--name'>Average <br/> Salary</span>
                                    <h1 className="global-heading cp-salaries--amount">
                                        25,000
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className='cp-salaries--wrapper mb-md-0 mb-3'>
                                    <span className='cp-salaries--name'>Top <br/> Salary</span>
                                    <h1 className="global-heading cp-salaries--amount">
                                        45,000
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className='cp-salaries--wrapper mb-md-0 mb-3'>
                                    <span className='cp-salaries--name'>Students <br/>Placed</span>
                                    <h1 className="global-heading cp-salaries--amount">
                                        75,000
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Section: Purpose of Internship */}
        <section className="cp-whyinternship">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                        <div className="cp-card">
                            <h1 className='global-heading mb-4'>Career Readiness<br/> Program</h1>
                            <p className='global-bodytext'>
                            Our Career Readiness Program prepares students for the job market by offering resume building workshops, interview preparation, and job search strategies. We empower our graduates with the skills and knowledge needed to stand out in a competitive job market.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                        <div className="cp-card">
                            <h1 className='global-heading mb-4'>Placement Success<br/> Rate</h1>
                            <p className='global-bodytext'>
                            Students can intern with multinational corporations, research labs, and innovative startups, working in areas like product development, R&D, and data analysis. By the end of the year, they’ll have built valuable skills and connections that often lead to job offers upon graduation.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Section: Top Carousel End*/}

        {/* Section: Industry Partners */}
        <section className="container">
            <div className="global-primeCarousel">
                <div className="row">
                    <div className="col-12 pt-0 mb-5">
                        <h1 className="global-heading mb-4">
                        Top Hiring Companies
                        </h1>
                        <p className="global-bodytext">
                        Sitare University graduates are working at some of the most respected organizations worldwide. Our top hiring partners include [list notable companies], with graduates taking on roles in engineering, data science, consulting, and more.
                        </p>
                    </div>
                </div>

                <CollageCarousel data={INDUSTRY_CONNECTIONS} />
            </div>
        </section>


         {/* Section: Student Support */}
         <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                        Career Counseling & Support
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <p className="global-bodytext">
                        Our dedicated Career Services team provides personalized support, helping students identify their career goals, build professional networks, and find job opportunities that align with their aspirations.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        {/* Section: Enquire Now */}
        <section className="global-blue--strip mt-5">
        <div className="container">
          <div className="d-flex align-items-center gap-5">
            <div>
              <p className="global-blue--stripapplytitle">Want to know more?</p>
              <h2 className="global-blue--stripapplybtn">Enquire Now</h2>
            </div>
            
            <Link to={"https://admissions.sitare.org/"} target="_blank" className="global-blue--striparrow">
              <i className="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </section>


        {/* Section: Student Experiences */}
        <section className="global-aboutwrapper">
            <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-md-5 col-12">
                        <h1 className="global-heading">
                            Student <br/> Experiences
                        </h1>
                    </div>
                    <div className="col-md-7 col-12">
                        <p className="global-bodytext">
                            Hear from our students! These firsthand stories highlight the practical skills, professional connections, and unforgettable experiences our students have gained through summer internships.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        {/* Section: Industry Partners Says */}
        <section className="in-insights">
        <h1 className="global-heading mb-4">
        Students Testimonials
            </h1>
            <div>
            <Carousel
                value={PARTNERS_REVIEW_DATA}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                className="custom-carousel in-insights--internship-carousel"
                circular={false}
                showIndicators={false}
                autoplayInterval={0}
                itemTemplate={studentsExpTemplate}
                prevIcon={<i className="fa-solid fa-angle-left"></i>}
                nextIcon={<i className="fa-solid fa-angle-right"></i>}
                indicatorsContentClassName="gdhshds"
            />
            </div>
        </section>

    </>
  )
}

export default JobAfterGraduation;