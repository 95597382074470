import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import CarouselBanner from "../../../src/assets/images/banner1.jpg";
import BeyondImg1 from "../../assets/images/b1.png";
import BeyondImg2 from "../../assets/images/b2.png";
import BeyondImg3 from "../../assets/images/b3.png";
import BeyondImg4 from "../../assets/images/b4.png";
import BeyondImg5 from "../../assets/images/b5.png";
import founderImage from "../../assets/images/founder.png";
import { Link } from "react-router-dom";
import { Carousel } from "primereact/carousel";
import INDUSTRY_CONNECTIONS from "../../dummyData/IndustryConnections.json";
import "./style.scss";

const HomePage = () => {
  const [products, setProducts] = useState([
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
  ]);

  const productTemplate = (product) => {
    return (
      <div className="border-1 surface-border border-round m-2 text-center px-0">
        <div>
          <h3 className="mb-1 sh-upcoming--eventsdate">{product}</h3>
          <h6 className="mt-0 mb-3 sh-upcoming--eventsmonth">june</h6>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Section: Top Carousel */}
      <section className="sh-home--carousel">
        <OwlCarousel
          items={1.1}
          className="owl-theme ps-3"
          loop
          nav
          dotClass="carousel-dot"
          dotsClass="carousel-dot-wrapper"
          navIcon={["PREV", "NEXT"]}
          margin={20}
        >
          <div className="position-relative sh-home--carouselitembox">
            <img className="img" src={CarouselBanner} alt="banner" draggable="false"/>
            <div className="sh-home--carouselbannertxt">
              <h4>
                Building AI talent for India,
                <br /> in India.
              </h4>
            </div>
          </div>
          <div className="position-relative sh-home--carouselitembox">
            <img className="img" src={CarouselBanner} alt="banner" draggable="false"/>
            <div className="sh-home--carouselbannertxt">
              <h4>
                Building AI talent for India,
                <br /> in India.
              </h4>
            </div>
          </div>
          <div className="position-relative sh-home--carouselitembox">
            <img className="img" src={CarouselBanner} alt="banner" draggable="false"/>
            <div className="sh-home--carouselbannertxt">
              <h4>
                Building AI talent for India,
                <br /> in India.
              </h4>
            </div>
          </div>
        </OwlCarousel>
      </section>
      {/* Section: Top Carousel End*/}

      {/* Section: Transforming Lives */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-lg-10">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <h1 className="global-heading">
                    Transforming Lives through World-Class Computer Science
                    Education
                  </h1>
                </div>
                <div className="col-lg-6 col-12">
                  <p className="global-bodytext">
                    At Sitare University, we believe in the power of education
                    to drive social upliftment and personal transformation.
                    Established in 2022 by Dr. Amit Singhal, former SVP of
                    Google Search and ACM Fellow, our mission is to provide
                    top-tier Computer Science education to bright students from
                    economically disadvantaged backgrounds. We aim to cultivate
                    future leaders in technology who will drive innovation and
                    change across the globe.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Services options */}
      <section className="container-fluid px-0">
        <div className="row mx-0">
          <div className="col-12 px-0">
            <div className="sh-service--grid">
              <Link
                to={"/curriculum"}
                className="sh-service--griditem box1"
              >
                Our
                <br />
                Courses
              </Link>
              <Link to={"/academic-Life"} className="sh-service--griditem box2">
                Academic
                <br />
                Life
              </Link>

              <Link to={"/facilities"} className="sh-service--griditem box3">
                Our
                <br />
                Campus
              </Link>
              <Link to={"/about"} className="sh-service--griditem box4">
                About Us
              </Link>
              <Link to={"/university-life"} className="sh-service--griditem box5">
                University
                <br />
                Life
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Innovative Curriculum */}
      <section className="sh-innovative">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-lg-10">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div>
                    <h1 className="global-heading mt-2 pe-lg-3">
                      Innovative Curriculum Designed for Industry Success
                    </h1>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <ul className="sh-innovative--list">
                    <li>
                      <b>Industry-Focused Education</b>
                      <p>
                        Our courses are designed to align perfectly with
                        industry requirements, ensuring students are job-ready
                        from day one.
                      </p>
                    </li>
                    <li>
                      <b>Hands-On Learning</b>
                      <p>
                        Emphasis on project-based learning allows students to
                        apply theoretical knowledge to practical problems.
                      </p>
                    </li>
                    <li>
                      <b>Personalized Attention</b>
                      <p>
                        With small class sizes and dedicated faculty, each
                        student receives the attention and support they need to
                        excel.
                      </p>
                    </li>
                    <li>
                      <b>Paid Internships</b>
                      <p>
                        Starting right after the first year, our students gain
                        valuable industry experience through paid internships.
                      </p>
                    </li>
                    <li>
                      <b>Industry Immersion</b>
                      <p>
                        The final year is completely spent working in the
                        industry, giving students a significant head start in
                        their careers.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Beyond Lecture Hall */}
      <section className="container">
        <div className="row">
          <div className="col-12 sh-beyond--heading">
            <h1 className="global-heading">Beyond lecture hall</h1>
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-12 col-xl-10">
            <div>
              <ul className="sh-beyond--wrapper">
                <li className="sh-beyond--wrapper-li ">
                  <img
                    className="sh-beyond--wrapper-img"
                    src={BeyondImg1}
                    alt="service"
                    draggable="false"
                  />
                  <div className="sh-beyond--wrapper-text">
                    <Link className="sh-beyond--wrapperplay" to={'https://www.youtube.com/watch?v=D2_x9Ov-1KI'} target="_blank" title="Play Video">
                    <i class="fa-regular fa-circle-play"></i></Link>

                    <Link to={'/'} className="sh-beyond--wrapper-textname">What it’s like to be at Sitare?</Link>
                  </div>
                </li>

                <li className="sh-beyond--wrapper-li">
                  <img
                    className="sh-beyond--wrapper-img"
                    src={BeyondImg2}
                    alt="service"
                    draggable="false"
                  />
                  <div className="sh-beyond--wrapper-text">
                    <Link to={'/'} className="sh-beyond--wrapper-textname">Alumni</Link>
                  </div>
                </li>

                <li className="sh-beyond--wrapper-li">
                  <img
                    className="sh-beyond--wrapper-img"
                    src={BeyondImg2}
                    alt="service"
                    draggable="false"
                  />
                  <div className="sh-beyond--wrapper-text">
                    <Link to={'/'} className="sh-beyond--wrapper-textname">Our Campus</Link>
                  </div>
                </li>

                <li className="sh-beyond--wrapper-li">
                  <img
                    className="sh-beyond--wrapper-img"
                    src={BeyondImg3}
                    alt="service"
                    draggable="false"
                  />
                  <div className="sh-beyond--wrapper-text">
                    <Link to={'/'} className="sh-beyond--wrapper-textname">Sports & Club</Link>
                  </div>
                </li>

                <li className="sh-beyond--wrapper-li">
                  <img
                    className="sh-beyond--wrapper-img"
                    src={BeyondImg4}
                    alt="service"
                    draggable="false"
                  />
                  <div className="sh-beyond--wrapper-text">
                  <Link to={'/student-projects'} className="sh-beyond--wrapper-textname">student projects</Link>
                  </div>
                </li>

                <li className="sh-beyond--wrapper-li">
                  <img
                    className="sh-beyond--wrapper-img"
                    src={BeyondImg2}
                    alt="service"
                    draggable="false"
                  />
                  <div className="sh-beyond--wrapper-text">
                  <Link to={'/'} className="sh-beyond--wrapper-textname">events & fests</Link>
                  </div>
                </li>

                <li className="sh-beyond--wrapper-li">
                  <img
                    className="sh-beyond--wrapper-img"
                    src={BeyondImg5}
                    alt="service"
                    draggable="false"
                  />
                  <div className="sh-beyond--wrapper-text">
                  <Link to={'/'} className="sh-beyond--wrapper-textname">faculty research</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Intake Apply */}
      <section className="global-blue--strip mt-5">
        <div className="container">
          <div className="d-flex align-items-center gap-5">
            <div>
              <p className="global-blue--stripapplytitle">2025 intake open</p>
              <h2 className="global-blue--stripapplybtn">Apply now</h2>
            </div>
            
            <Link to={"https://admissions.sitare.org/"} target="_blank" className="global-blue--striparrow">
              <i className="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </section>

      {/* Section: News and Updates */}
      <section className="sh-news py-5">
        <div className="container">
          <div className=" row mb-5">
            <div className="col-12">
              <p className="sh-news--subheading">News and Updates</p>
              <h2 className="global-heading">What's happening at Sitare?</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="sh-news--articles mb-4 mb-lg-0">
                <div className="row">
                  <div className="col-md-5 col-12">
                    <div>
                      <img
                        src="https://via.placeholder.com/150"
                        alt="image"
                        className="sh-news--articlesimg"
                      />
                      <div className="sh-news--articlesdisplay">
                        <p className="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                        <i className="fa-solid fa-arrow-up"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 mt-3 mt-md-0">
                    {/* Item 1 */}
                    <div className="sh-news--articlesitem">
                      <img
                        src="https://via.placeholder.com/150"
                        alt="image"
                        className="sh-news--articlesitemthumb"
                      ></img>
                      <div>
                        <p className="sh-news--articlesitemdesc">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit lorem
                        </p>
                        <Link
                          to={"/"}
                          className="sh-news--articlesitemmore my-1"
                        >
                          Read more
                        </Link>
                        <p className="sh-news--articlesitemdate">
                          Wednesday, 14 June, 2024
                        </p>
                      </div>
                    </div>

                    {/* Item 2 */}
                    <div className="sh-news--articlesitem">
                      <img
                        src="https://via.placeholder.com/150"
                        alt="image"
                        className="sh-news--articlesitemthumb"
                      ></img>
                      <div>
                        <p className="sh-news--articlesitemdesc">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit lorem
                        </p>
                        <Link
                          to={"/"}
                          className="sh-news--articlesitemmore my-1"
                        >
                          Read more
                        </Link>
                        <p className="sh-news--articlesitemdate">
                          Wednesday, 14 June, 2024
                        </p>
                      </div>
                    </div>

                    {/* Item 3 */}
                    <div className="sh-news--articlesitem">
                      <img
                        src="https://via.placeholder.com/150"
                        alt="image"
                        className="sh-news--articlesitemthumb"
                      ></img>
                      <div>
                        <p className="sh-news--articlesitemdesc">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit lorem
                        </p>
                        <Link
                          to={"/"}
                          className="sh-news--articlesitemmore my-1"
                        >
                          Read more
                        </Link>
                        <p className="sh-news--articlesitemdate">
                          Wednesday, 14 June, 2024
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mb-0 text-end">
                      {/* View All Articles */}
                      <Link to={"/news-updates"} className="sh-news--viewall">
                        View All Articles
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="sh-upcoming">
                <div className="sh-upcoming--header">
                  <p>upcomming events</p>
                  <Link to={"/"} className="sh-upcoming--headerviewall">
                    View all
                  </Link>
                </div>
                <div className="sh-upcoming--events">
                  <Carousel
                    value={products}
                    numVisible={3}
                    numScroll={3}
                    // responsiveOptions={responsiveOptions}
                    className="custom-carousel"
                    circular
                    showIndicators={false}
                    autoplayInterval={0}
                    itemTemplate={productTemplate}
                    prevIcon={<i className="fa-solid fa-angle-left"></i>}
                    nextIcon={<i className="fa-solid fa-angle-right"></i>}
                  />
                </div>
                <p className="sh-upcoming--eventsdesc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p>
                <Link to={"/"} className="sh-upcoming--headerviewall">
                  View this event
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: University Life */}
      <section className="container-fluid px-0">
        <div className="row mx-0">
          <div className="col-12 px-0">
            <div className="sh-studentlife--grid">
              <a className="sh-studentlife--griditem box1">
                University Life
                <br />
                at Sitare
              </a>
              <Link to={"/hostels"} className="sh-studentlife--griditem box2">
              <span>Hostel</span>
              </Link>
              <Link to={"/hostels"} className="sh-studentlife--griditem box2">
              <span>Hostel</span>
              </Link>
              <Link to={"/hostels"} className="sh-studentlife--griditem box3">
              <span>Cafeteria</span>
              </Link>
              <Link to={"/hostels"} className="sh-studentlife--griditem box4">
              <span>Class Room</span>
              </Link>
              <Link to={"/hostels"} className="sh-studentlife--griditem box5">
              <span>sports</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Top Carousel */}
      <section className="container">
        <div className="sh-connections">
          <div className="row">
            <div className="col-12 global-section--center">
              <h1 className="global-heading text-center">
                Our industry connections
              </h1>
            </div>
          </div>

          <OwlCarousel
            items={1}
            className="sh-connections--owl-theme"
            loop
            nav
            dotClass="sh-connections--carousel-dot-wrapper--carousel-dot"
            dotsClass="sh-connections--carousel-dot-wrapper"
            navIcon={["PREV", "NEXT"]}
            margin={12}
          >
            {INDUSTRY_CONNECTIONS &&
              INDUSTRY_CONNECTIONS.length > 0 &&
              INDUSTRY_CONNECTIONS.map((item, index) => (
                <div key={item.id} className="sh-connections--owl-theme--grid">
                  {item.images &&
                    item.images.length > 0 &&
                    item.images.map((imageItem, imgInd) => (
                      <div
                        key={imgInd}
                        className="sh-connections--owl-theme--griditem"
                      >
                        <img className="img" src={imageItem} alt="image" draggable="false"/>
                      </div>
                    ))}
                </div>
              ))}
          </OwlCarousel>
        </div>
      </section>

      {/* Section: Enquire Now */}
      <section className="global-blue--strip mt-5">
        <div className="container">
          <div className="d-flex align-items-center gap-5">
            <div>
              <p className="global-blue--stripapplytitle">Want to know more?</p>
              <h2 className="global-blue--stripapplybtn">Enquire Now</h2>
            </div>
            <Link to={"/contact-us"} className="global-blue--striparrow">
              <i className="fa-solid fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </section>

      {/* Section: Founder Quote */}
      <section className="container-fluid px-0">
        <div className="row mx-0">
          <div className="col-lg-7 col-md-6 col-12 px-0">
            <div className="sh-founder">
              <img
                className="sh-founder--img"
                src={founderImage}
                alt="founder"
                draggable="false"
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-12 px-0">
            <div className="sh-founder--quote">
              <h1>A quote from the founder.</h1>
              <p>
                "The future belongs to nations who can make fundamental advances
                in Computer Science."
              </p>
              <Link to={"/why-sitare"} className="global-orangeBtn">
              know more
                </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
