import React from "react";
import "./style.scss";
import LogoWhite from "../../assets/images/logo_white.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="footer_wrapper">
        <div className="container">
          <div className="row">
            {/* ADDRESS SECTION BEGINS */}
            <div className="col-xl-4 col-md-6 col-12">
              <div className="logo">
                <Link to={"/"}>
                  <img src={LogoWhite} alt="logo" draggable="false" />
                </Link>
              </div>
              <div className="suf-contact--info">
                <h6 className="suf-contact--infoheading">address</h6>
                <p className="suf-contact--infotext">
                  1st floor, Vatika Triangle, Mehrauli-Gurgaon Rd, Sector 28,
                  Maruti Housing Colony, Gurugram, Sarhol, Haryana 122002
                </p>
              </div>

              <div className="suf-contact--info">
                <h6 className="suf-contact--infoheading">email</h6>
                {/* <a
                  href="mailto:university@sitare.org"
                  className="suf-contact--infotext"
                >
                  university@sitare.org
                </a> */}
                <Link to={"mailto:university@sitare.org"} className="suf-contact--infotext">
                  university@sitare.org
                </Link>
              </div>

              <div className="suf-contact--info mb-0">
                <h6 className="suf-contact--infoheading">Phone/ WhatsApp</h6>
                <Link to={"tel:+917849910085"} className="suf-contact--infotext">
                  +91 78499 10085
                </Link>
              </div>
            </div>

            {/* QUICK LINKS SECTION */}
            <div className="col-xl-4 col-md-6 col-12">
              <div className="suf-contact--link">
                <h3 className="suf-contact--linkheading">quick links</h3>
                <div className="suf-contact--linkoption">
                  <ul className="suf-contact--linkoptions">
                    <li>
                      <Link to={"/curriculum"}>Curriculum</Link>
                    </li>
                    {/* <li>
                      <Link to={"*"}>Admissions</Link>
                    </li> */}
                    <li>
                      <Link to={"/university-life"}>University Life</Link>
                    </li>
                    <li>
                      <Link to={"*"}>Alumni</Link>
                    </li>
                    <li>
                      <Link to={"*"}>Career</Link>
                    </li>
                    {/* <li>
                      <Link to={"*"}>Admissions</Link>
                    </li> */}
                    <li>
                      <Link to={"/contact-us"}>Contact Us</Link>
                    </li>
                  </ul>
                  <ul className="suf-contact--linkoptions">
                    <li>
                      <Link to={"/about"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"/our-people"}>Our People</Link>
                    </li>
                    <li>
                      <Link to={"/news-updates"}>News & Updates</Link>
                    </li>
                    <li>
                      <Link to={"/academic-Life"}>Academic Life</Link>
                    </li>
                    <li>
                      <Link to={"*"}>Campus</Link>
                    </li>
                    <li>
                      <Link to={"/events-activities"}>Events</Link>
                    </li>
                    <li>
                      <Link to={"/blogs"}>Blog</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* SOCIAL MEDIA AND SUBSCRIBE SECTION */}
            <div className="col-xl-4 col-md-6 col-12">
              <div className="suf-contact--social mt-md-4 mt-xl-0">
                <h3 className="suf-contact--linkheading">social media</h3>
                <ul className="suf-contact--sociallink">
                  {/* <li>
                    <Link to={"https://fontawesome.com/"} target="_blank" title="Facebook">
                      <i className="fa-brands fa-facebook"></i>
                    </Link>
                  </li> */}
                  <li>
                    <Link to={"https://www.instagram.com/sitarefdn/?hl=en"} target="_blank" title="Instagram">
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={"https://www.linkedin.com/school/sitare-university/posts/?feedView=all"} target="_blank" title="Linkedin">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={"https://www.youtube.com/@sitarefoundation"} target="_blank" title="Youtube">
                      <i className="fa-brands fa-youtube"></i>
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <div className="suf-contact--social">
                <h3 className="suf-contact--linkheading">
                  Subscribe to our newsletter
                </h3>
                <div className="suf-subscribe">
                  <input
                    type="text"
                    className="suf-subscribe--field"
                    placeholder="vibin@headlesshippies.com"
                  />
                  <button type="button" className="suf-subscribe--button">
                    subscribe
                  </button>
                </div>
              </div> */}

              <div>
              
              {/* <Link to={"https://admissions.sitare.org/"} target="_blank" title="Youtube" className="global-orangeBtn">
              apply now</Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
