import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import hostelBanner from "../../assets/images/hostelBanner.png";
import hostel1 from "../../assets/images/hostel/h1.png";
import hostel2 from "../../assets/images/hostel/h2.png";
import hostel3 from "../../assets/images/hostel/h3.png";
import hostel4 from "../../assets/images/hostel/h4.png";
import hostel5 from "../../assets/images/hostel/h5.png";
import hostel6 from "../../assets/images/hostel/h6.png";
import MenuCards from "../../components/sections/MenuCards";
import { FACILITIES_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const HostelsPage = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    navigate(`/${route}`);
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={hostelBanner}
          title="University Life - Facilities - Hostels"
          desc="Safe, Comfortable Living for a Focused Education"
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: About Text */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
                Comfortable and Secure Accommodation
              </h1>
              <p className="global-bodytext">
                At Sitare University, our on-campus hostels provide a safe,
                welcoming environment for students to live and learn. The
                hostels are designed to offer a comfortable and homely
                experience, fostering a strong sense of community among
                students.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Facilites */}
      <section className="ul-facilites">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="ul-facilites--list">
                <li>
                  <b className="global-subheading global-fw600">
                    Facilities & Amenities:
                  </b>
                  <p className="global-bodytext">
                    Fully furnished rooms with modern amenities, 24/7 security,
                    high-speed internet, and housekeeping services.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Living Spaces:
                  </b>
                  <p className="global-bodytext">
                    Shared and single room options available, with common areas
                    for study, socializing, and relaxation.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Safety & Wellbeing:
                  </b>
                  <p className="global-bodytext mb-3">
                    Shared and single room options available, with common areas
                    for study, On-site wardens and medical facilities ensure
                    that students are cared for at all times.
                  </p>
                  <p className="global-bodytext">
                    Special care is taken of our female students to ensure that
                    they can live and learn freely on our campus without any
                    fear.
                  </p>
                </li>
                <li>
                  <b className="global-subheading global-fw600">
                    Hostel Timings:
                  </b>
                  <p className="global-bodytext">
                    Entry and exit are allowed between 6:00 AM and 10:00 PM for
                    students' safety and convenience.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Images */}
      <section className="container">
        <div className="row">
          <div className="col-12">
            <div className="ul-imgcards">
              <img src={hostel1} alt="images" draggable="false" />
              <img src={hostel2} alt="images" draggable="false" />
              <img src={hostel3} alt="images" draggable="false" />
              <img src={hostel4} alt="images" draggable="false" />
              <img src={hostel5} alt="images" draggable="false" />
              <img src={hostel6} alt="images" draggable="false" />
            </div>
          </div>
        </div>
      </section>

      {/* Section: Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={FACILITIES_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme"/>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HostelsPage;
