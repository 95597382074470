import React from 'react';
import './style.scss';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import BannerImg from "../../assets/images/work_with_us_banner.png";
import "../../assets/styles/styles.scss";
import VACANCIES from '../../dummyData/Vacancies.json'
import VacancyDetails from '../../components/sections/VacancyDetails';

const AcademicVacancies = () => {
  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
            imageUrl={BannerImg}
            title="about us - work with us"
            desc="Join the Mission at Sitare"
            themeColor="yellow-theme"
        />
      </section>

      {/* Section: Vacancies */}
      <section className="wu-vacancies">
        <section className='container'>
          <h1 className="global-heading mb-4">Academic Vacancies</h1>
          <ul className='wu-vacancies--list'>
            {VACANCIES && VACANCIES.length > 0 && VACANCIES.map((item, index) => (
              <React.Fragment key={item?.id}>
                <li className='wu-vacancies--listitem'>
                  <h3 className='wu-vacancies--listitem--title mb-0'>{item?.title}</h3>
                  <p className="wu-vacancies--listitem--deatils mb-0" data-bs-toggle="offcanvas" data-bs-target={`#vacancyDetails_${item?.id}`} aria-controls={`vacancyDetails_${item?.id}`}>
                    View details
                  </p>
                </li>

                <VacancyDetails item={item} vacancyType={"Administrative Vacancies"} />
              </React.Fragment>
            ))}
          </ul>
        </section>
      </section>
    </>
  )
}

export default AcademicVacancies;