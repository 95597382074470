import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import FestBanner from "../../assets/images/Fests/FestBanner.png";
import MenuCards from "../../components/sections/MenuCards";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";
import auditorium1 from "../../assets/images/auditorium/a1.png";
import auditorium2 from "../../assets/images/auditorium/a2.png";
import auditorium3 from "../../assets/images/auditorium/a3.png";
import auditorium4 from "../../assets/images/auditorium/a4.png";
import auditorium5 from "../../assets/images/auditorium/a5.png";
import auditorium6 from "../../assets/images/auditorium/a6.png";

const FestsPage = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    if(route.includes("http:") || route.includes("https:")){
      window.open(route, "_blank")
    }else{
      navigate(`/${route}`);
    }
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={FestBanner}
          title="University Life - Events & Activities - Fests"
          desc="Where Innovation Meets Celebration."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Celebrate */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h1 className="global-heading mb-4">
              Celebrate Culture, Creativity, and Innovation
              </h1>
              <p className="global-bodytext">
              University life isn’t complete without the excitement of festivals, and Sitare University knows how to celebrate! Our fests bring together the entire campus community for unforgettable experiences, from cultural performances to academic competitions.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Culture Cards */}
      <section className="ei-culture">
        <div className="container">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="ei-culture--card">
              <h3 className="global-subheading text-white mb-3">Annual Cultural Fest</h3>
              <p className="global-bodytext text-white">Our flagship cultural fest is a multi-day event filled with music, dance, drama, and fun. Students showcase their talents, compete in performances, and enjoy celebrity appearances, food stalls, and interactive experiences.</p>
            </div>
          </div>

          <div className="col-md-4 col-12">
            <div className="ei-culture--card">
              <h3 className="global-subheading text-white mb-3">Tech Fest</h3>
              <p className="global-bodytext text-white">Tech enthusiasts can immerse themselves in our cutting-edge tech fest, which includes coding competitions, hackathons, tech talks, and exhibitions. It’s the perfect platform for students to demonstrate their skills and collaborate with industry experts.</p>
            </div>
          </div>

          <div className="col-md-4 col-12">
            <div className="ei-culture--card">
              <h3 className="global-subheading text-white mb-3">Entrepreneurship Fest</h3>
              <p className="global-bodytext text-white">Aspiring entrepreneurs can pitch their ideas, attend workshops, and network with venture capitalists and startup founders during our Entrepreneurship Fest. The event is designed to ignite the entrepreneurial spirit and foster innovative thinking.</p>
            </div>
          </div>
        </div>
        </div>
      </section>

      {/* Section: Why Join */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading mb-4">Why attend our Fests?</h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext mb-5">
                  Our fest is a vibrant blend of innovation, culture, and creativity, offering something for everyone. You'll experience thrilling competitions, inspiring workshops, and electrifying performances that bring students together. Join us for unforgettable moments and the chance to make new connections while showcasing your talents!
                  </p>
                  <div>
                    <button type="button" class="global-orangeBtn px-5">
                      More Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Fest Highlights */}
      <section className="ei-highlights">
            <div className="container">
            <div className="row justify-content-md-center">
              <h1 className="global-heading mb-4">
                Fest Highlights:
              </h1>

              <ul className="ei-highlights--list">
                <li className="ei-highlights--list-item global-subheading">Cultural performances, DJ nights, and talent shows.</li>
                <li className="ei-highlights--list-item global-subheading">Tech challenges, coding sprints, and idea hackathons.</li>
                <li className="ei-highlights--list-item global-subheading">Workshops, masterclasses, and competitions across disciplines.</li>
              </ul>
              
              {/* <div>
                {UPCOMING_EVENTS && UPCOMING_EVENTS.length > 0 && UPCOMING_EVENTS.map((item, index) => (
                  <div className='ei-schedule--item'>
                    <h3 className='ei-schedule--item-date'>{item?.date}</h3>
                    {item?.programs && item?.programs.length > 0 && item?.programs.map((program, ind) => (
                      <div className='ei-schedule--item-content'>
                        <p className='ei-schedule--item-title'>{program?.title}</p>
                        <p className='ei-schedule--item-desc global-bodytext'>{program?.desc}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div> */}
            </div>
          </div>
      </section>

      {/* Section: Gallery */}
      <section className="container">
        <div className="row">
          <div className="col-12">
          <h1 className="global-heading mb-4">Gallery</h1>
          <div className="ei-imgcards">
              <img src={auditorium1} alt="images" draggable="false" />
              <img src={auditorium2} alt="images" draggable="false" />
              <img src={auditorium3} alt="images" draggable="false" />
              <img src={auditorium4} alt="images" draggable="false" />
              <img src={auditorium5} alt="images" draggable="false" />
              <img src={auditorium6} alt="images" draggable="false" />
            </div>
          </div>
        </div>
      </section>


      {/* Section:Menu Cards */}
      <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <MenuCards
                data={EVENTS_MENU_DATA}
                handleMenuClick={handleMenuClick}
                themeColor="sh-menuCard--orange-theme"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FestsPage;
