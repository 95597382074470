import React from "react";
import "./style.scss";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import BannerImg from "../../assets/images/activities_banner.png";
import ACTIVITIES_DATA from "../../dummyData/ActivitiesData.json";
import MenuCards from "../../components/sections/MenuCards";
import { EVENTS_MENU_DATA } from "../../constants";
import { useNavigate } from "react-router-dom";

const ClassActivities = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    navigate(`/${route}`);
  };

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="Activities"
          desc="Get Involved, Get Inspired."
          themeColor="sh-contentimgsplit--orange-theme"
        />
      </section>

      {/* Section: Beyond Classroom */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 col-12">
                  <h1 className="global-heading">
                    Thriving Beyond the Classroom
                  </h1>
                </div>
                <div className="col-md-7 col-12">
                  <p className="global-bodytext">
                    At Sitare University, we believe that education goes beyond
                    the classroom. Our vibrant student life is filled with
                    opportunities for growth, exploration, and community
                    engagement. Whether you're passionate about technology,
                    arts, sports, or social causes, there's a place for you to
                    thrive. Through a wide range of clubs, events, and
                    initiatives, we encourage students to develop their talents,
                    build lasting friendships, and make a positive impact on the
                    world around them. Join us in creating unforgettable
                    memories and shaping a dynamic campus culture that
                    celebrates diversity, creativity, and innovation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Upcoming Activities */}
      <section className="ca-activities">
        <div className="container">
          <h1 className="global-heading mb-4">upcoming activities</h1>
          <p className="global-bodytext">
            Discover what's happening next at Sitare University! Our Upcoming
            Events section highlights exciting activities, workshops, and
            competitions on the horizon. From tech hackathons to cultural
            festivals and guest lectures, there's always something to look
            forward to. Get ready to dive into campus life!
          </p>

          <ul className="card-upcoming--list mt-5">
            {ACTIVITIES_DATA &&
              ACTIVITIES_DATA.length > 0 &&
              ACTIVITIES_DATA.map((item, index) => {
                if (item?.status == "upcoming") {
                  return (
                    <li className="card-upcoming--item">
                      <div className="card-upcoming-content">
                        <div className="card-upcoming-contentimg">
                        <img src={item?.image} alt="image" draggable="false"/>
                        </div>
                        <div>
                          <h3 className="global-subheading global-fw600 mb-3">
                            {item?.name}
                          </h3>
                          <p className="global-bodytext global-lineclamp mb-2">
                            Topic: {item?.topic}
                          </p>
                          <p className="global-bodytext mb-2">
                            Date: {item?.date}
                          </p>
                          <p className="global-bodytext mb-2">
                            Status: {item?.status}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate(`/upcoming-activities`)}
                        className="global-orangeBtn flex-shrink-0"
                      >
                        know more
                      </button>
                    </li>
                  );
                }
              })}
          </ul>
        </div>
      </section>

      {/* Section: Past Activities */}
      <section className="ca-activities">
        <div className="container">
          <h1 className="global-heading mb-4">past activities</h1>
          <p className="global-bodytext">
            Explore the memorable events that have shaped Sitare University. Our
            Past Events section features highlights and achievements from
            previous activities, including hackathons, cultural fests, and
            workshops. Relive the moments that inspired our community.
          </p>

          <ul className="card-upcoming--list mt-5">
            {ACTIVITIES_DATA &&
              ACTIVITIES_DATA.length > 0 &&
              ACTIVITIES_DATA.map((item, index) => {
                if (item?.status === "completed") {
                  return (
                    <li className="card-upcoming--item card-past">
                      <div className="card-upcoming-content">
                        <div className="card-upcoming-contentimg">
                          <img src={item?.image} alt="image" draggable="false"/>
                        </div>
                        
                        <div>
                          <h3 className="lobal-subheading global-fw600 mb-3">
                            {item?.name}
                          </h3>
                          <p className="global-bodytext global-lineclamp mb-2">
                            Topic: {item?.topic}
                          </p>
                          <p className="global-bodytext mb-2">
                            Date: {item?.date}
                          </p>
                          <p className="cglobal-bodytext mb-2">
                            Status: {item?.status}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => navigate("/past-activities")}
                        className="global-orangeBtn flex-shrink-0"
                      >
                        View activities
                      </button>
                    </li>
                  );
                }
              })}
          </ul>
        </div>
      </section>

      <section className="container py-lg-5 mb-5 se-cards">
        <MenuCards
          data={EVENTS_MENU_DATA}
          handleMenuClick={handleMenuClick}
          themeColor="sh-menuCard--orange-theme"
        />
      </section>
    </>
  );
};

export default ClassActivities;
