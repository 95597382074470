import React from "react";
import { useNavigate } from "react-router-dom";
import SecondaryBanner from "../../components/sections/SecondaryBanner";
import thumbnail from "../../assets/images/hostel.png";
import BannerImg from "../../assets/images/student_projects.png";
import fullBanner from "../../assets/images/pedagogy_img.png";
import "./style.scss";
import "../../assets/styles/styles.scss";
import { Link } from "react-router-dom";
import { PROJECTS_MENU_DATA } from '../../constants';
import MenuCards from '../../components/sections/MenuCards';

const FaculityProjects = () => {
  const navigate = useNavigate();

  const handleMenuClick = (route) => {
    navigate(`/${route}`);
}

  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
          imageUrl={BannerImg}
          title="Academic Life - Projects - Faculty Projects"
          desc="Advancing Knowledge and Innovation"
          themeColor="sh-contentimgsplit--yellow-theme"
        />
      </section>

      {/* Section: Real World Content */}
      <section className="global-aboutwrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="global-heading mb-4">
              From Academic Insight to Impact
              </h1>
              <p className="global-bodytext">
              At Sitare University, our faculty members are not only educators but also innovators and thought leaders. Their research and projects go beyond the classroom, addressing real-world challenges and advancing knowledge across various fields. Faculty-led projects span a wide range of topics, from pioneering technological advancements to societal-impact initiatives. Through these projects, our faculty push the boundaries of their disciplines, collaborating with industry partners, research institutions, and students to bring concepts to life. The results often lead to cutting-edge research, publications, patents, and solutions that benefit both academia and industry.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Pedagogy Banner */}
      <section className="global-fullBanner">
        <div className="container">
          <img
            src={fullBanner}
            alt="Banner"
            draggable="false"
          />
        </div>
      </section>

      {/* Section: Featured Projects By Students */}
      <section className="container">
        <div className="row my-lg-5">
          <div className="col-12">
            <div className="">
              <h1 className="global-heading mb-4">
              Featured Projects by Faculties
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* Section: Project Box */}
      <section className="container">
        <div className="row mb-5">
          {/* Project 1 */}
          <div className="col-12 col-md-6 mb-3">
            <div className="fp-project">
              <div className="fp-project--thumbnail">
                <img src={thumbnail} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                Harnessing AI for Smart Healthcare Solutions
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link
                    to={
                      "/"
                    }
                    target="_blank"
                    className="global-orangeBtn"
                  >
                    View Project
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Lead Faculty Member:</b>
                  <span className="global-label">Dr. Anjali Verma, Professor of Artificial Intelligence</span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Collaborators:</b>
                  <span className="global-label">ABC Tech Labs</span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                  This project focuses on using Artificial Intelligence to develop predictive models that assist in early diagnosis and treatment of chronic diseases. Through collaboration with hospitals and AI research labs, the project aims to enhance healthcare outcomes.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Project 2 */}
          <div className="col-12 col-md-6 mb-3">
            <div className="fp-project">
              <div className="fp-project--thumbnail">
                <img src={thumbnail} alt="featured project" draggable="false" />
              </div>
              <div>
                <h3 className="global-subheading global-fw600 text-capitalize my-4">
                Sustainable Energy Solutions for Rural India
                </h3>
                {/* View More Button */}
                <div className="mb-5">
                  <Link
                    to={
                      "/"
                    }
                    target="_blank"
                    className="global-orangeBtn"
                  >
                    View Project
                  </Link>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Lead Faculty Member:</b>
                  <span className="global-label">Dr. Rajesh Kumar, Professor of Environmental Engineering</span>
                </div>

                {/* Project Detail Text */}
                <div className="mb-4">
                  <b className="global-label global-fw600">Collaborators:</b>
                  <span className="global-label">
                    RIndian Institute of Energy Research
                  </span>
                </div>

                {/* Project Detail Text */}
                <div>
                  <p className="global-bodytext global-lineclamp">
                  Develop low-cost, sustainable energy systems for rural areas in India. By leveraging solar and wind technologies, the project seeks to provide affordable, renewable energy to underserved communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

            {/* Section: Menu Cards */}
            <section className="global-bgmenucard">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <MenuCards data={PROJECTS_MENU_DATA} handleMenuClick={handleMenuClick} themeColor="sh-menuCard--yellow-theme" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaculityProjects;
