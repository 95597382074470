import React from 'react';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import PeopleImg from "../../assets/images/people_Img.png";
import MenuCards from '../../components/sections/MenuCards';
import { MENU_CARDS_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        navigate(`/${route}`);
    }

    return (
        <>
            <section className='mb-5'>
                <SecondaryBanner
                    imageUrl= {PeopleImg} 
                    title= "about us"
                    desc= "Transforming Lives through World-Class Computer Science Education"
                    themeColor="sh-contentimgsplit--red-theme"
                />
            </section>
            <section className='container py-lg-5 mb-5'>
                <MenuCards data={MENU_CARDS_DATA} handleMenuClick={handleMenuClick} />
            </section>
        </>
    )
}

export default AboutUs