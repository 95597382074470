// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.se-cards .sh-menuCard li:last-child {
  grid-column-start: unset;
}`, "",{"version":3,"sources":["webpack://./src/pages/eventsAndActivities/style.scss"],"names":[],"mappings":"AAEQ;EACI,wBAAA;AADZ","sourcesContent":[".se{\n    &-cards{\n        .sh-menuCard li:last-child{\n            grid-column-start: unset;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
