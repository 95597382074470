import React from 'react';
import SecondaryBanner from '../../components/sections/SecondaryBanner';
import BannerImg from "../../assets/images/about/aboutbanner.jpg";
import MenuCards from '../../components/sections/MenuCards';
import { MENU_CARDS_DATA } from '../../constants';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate();

    const handleMenuClick = (route) => {
        if (route.includes("http:") || route.includes("https:")) {
            window.open(route, "_blank")
        } else {
            navigate(`/${route}`);
        }
    }

    return (
        <>
        {/* Section: Banner */}
        <section className="mb-5">
          <SecondaryBanner
            imageUrl={BannerImg}
            title="About Us"
            desc="Transforming Lives through World-Class Computer Science Education"
            themeColor="sh-contentimgsplit--red-theme"
          />
        </section>
  
        {/* Section: What is Sitare */}
        {/* <section className="global-aboutwrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 class="global-heading mb-4">What is Sitare University?</h1>
                <p className="global-bodytext mb-4">
                  Dr. Amit Singhal, formerly the Senior Vice President at Google,
                  founded a revolutionary initiative aimed at providing high
                  quality Computer Science education, completely free of cost to
                  students from underprivileged backgrounds. This initiative
                  stands out not only for its noble mission but also for its
                  unwavering commitment to maintaining high standards of
                  education. Despite being a free program, the quality of teaching
                  and faculty at our institution surpasses that of most expensive
                  private universities and even several of the newly established
                  IITs. 
                </p>
  
                <p className="global-bodytext mb-4">
                  Our faculty members bring with them extensive expertise and a
                  passion for teaching, creating a conducive learning environment
                  that encourages academic growth and innovation. This initiative
                  is a testament to our belief that a student’s economic level
                  should not be a barrier to access quality education. By offering
                  this opportunity, we empower students to excel in the field of
                  Computer Science, equipping them with the knowledge and skills
                  necessary to thrive in today's technology-driven world. Our
                  commitment to excellence, combined with our dedication to
                  inclusivity and accessibility, sets us apart as a beacon of hope
                  and opportunity in the field of higher education. 
                </p>
                <p className="global-bodytext">
                  Sitare University is fully funded by Dr. Amit Singhal through
                  the Hope and Dreams Trust. We have a clear plan for long-term
                  financial sustainability of the university through our
                  endowment, external student scholarships and few other sources.
                  Our permanent campus is coming up on the Bhopal-Indore highway,
                  and will be ready by October 2025. Till then, we are operating
                  from Lucknow in partnership with Shri Ramswaroop Memorial
                  University.
                </p>
              </div>
            </div>
          </div>
        </section> */}
  
        {/* Section: What's innovation */}
        {/* <section className="global-aboutwrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 class="global-heading mb-4">
                  What’s innovative about our curriculum
                </h1>
                <p className="global-bodytext mb-3">
                  Sitare University distinguishes itself from traditional
                  institutions with its industry-focused curriculum, designed to
                  equip students for successful industry careers from day one. Our
                  curriculum balances modern job market demands with established
                  academic principles, ensuring a robust foundation for every
                  student. We are committed to nurturing high-earning
                  professionals through dedicated teaching efforts. To ensure that
                  no student is left behind, we pay individual attention to all
                  students, and provide extra help as and when required.
                </p>
  
                <p className="global-bodytext">
                  From the first year itself, our students benefit from paid
                  internships, providing invaluable real-world experience that is
                  typically hard to secure before the third year at even IITs and
                  NITs. Our faculty members not only provide strong support but
                  also foster a collaborative environment for students to learn
                  team work, supported by a network of experienced company
                  founders and advisors. As we continue to build our brand, Sitare
                  University remains dedicated to empowering students with the
                  skills and opportunities needed to excel in today's competitive
                  job market.
                </p>
              </div>
            </div>
          </div>
        </section> */}
  
        {/* Section: Why Sitare */}
        {/* <section className="global-aboutwrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 class="global-heading mb-4">
                  Why is Sitare University needed?
                </h1>
                <p className="global-bodytext mb-4">
                  Sitare University is deeply committed to ensuring fairness and
                  inclusivity in education. We understand the challenges posed by
                  the current JEE entrance exam system, which often disadvantages
                  students who cannot afford expensive coaching. This system tends
                  to favor those with good financial resources, thus perpetuating
                  inequalities. At Sitare University, our mission is to level the
                  playing field by providing accessible high quality educational
                  opportunities that prioritize merit and potential over economic
                  status. In addition, Sitare University fulfills a crucial role
                  in today's educational landscape for several other reasons:
                </p>
  
                <ol className="ps-3">
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Accessible Quality Education:</b> It provides high-quality
                      education that is accessible to students from diverse
                      backgrounds, including those from underprivileged
                      communities, offering opportunities that may not be readily
                      available elsewhere.
                    </p>
                  </li>
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Innovative Learning Environment:</b> Sitare University fosters
                      an environment that encourages innovation and critical
                      thinking, preparing students to thrive in a rapidly evolving
                      world driven by technological advancements.
                    </p>
                  </li>
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Holistic Development:</b> The university focuses not only on
                      academic excellence but also on the holistic development of
                      students, nurturing their innate talents, leadership skills,
                      and ethical values.
                    </p>
                  </li>
                  <li>
                    <p className="global-bodytext mb-2">
                      <b>Industry-Relevant Curriculum:</b> It offers a curriculum
                      designed in consultation with global industry experts,
                      ensuring that students are equipped with the skills and
                      knowledge necessary to meet current and future market
                      demands.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section> */}
  
        {/* Section: Our Vision */}
        {/* <section className="container" style={{marginBottom: "50px"}}>
          <div className="row">
            <div className="col-12">
              <div className="sa-sitare--vision">
                <p>
                  Our vision is to create a fair educational environment where
                  every student, regardless of their financial background, can
                  thrive and succeed. Sitare University plays a pivotal role in
                  shaping future leaders, thinkers, and innovators who will
                  contribute positively to society, making a meaningful impact
                  globally.
                </p>
              </div>
            </div>
          </div>
        </section> */}
  
        {/* Section: Menu Cards */}
        <section className="global-bgmenucard">
          <div className="container">
            <div className="row">
              <div className="col-12">
              <MenuCards data={MENU_CARDS_DATA} handleMenuClick={handleMenuClick} />
              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default AboutUs