import React from 'react';
import './style.scss';
import BannerImg from "../../assets/images/contact/contactbanner.jpg";
import SecondaryBanner from '../../components/sections/SecondaryBanner';

const ContactUs = () => {
  return (
    <>
      {/* Section: Banner */}
      <section>
        <SecondaryBanner
            imageUrl={BannerImg}
            title="contact us"
            desc="We’re Here to Help. Reach Out and Start Your Journey."
            themeColor="sh-contentimgsplit--orange500-theme"
            textColor="text-white"
        />
      </section>

      {/* Section: Contact List */}
      <section className='cu-enquries'>
        <div className='container'>
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item cu-enquries--item">
              <h2 className="accordion-header cu-enquries--header" id="flush-headingOne">
                <button className="accordion-button collapsed cu-enquries--button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  General Enquiries
                </button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body cu-enquries--body">
                  <p>Email:university@sitare.org</p>
                  {/* <p>Phone/WhatsApp: +91 78499 10085</p> */}
                </div>
              </div>
            </div>
            <div className="accordion-item cu-enquries--item">
              <h2 className="accordion-header cu-enquries--header" id="flush-headingTwo">
                <button className="accordion-button collapsed cu-enquries--button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                Admission Enquiries
                </button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body cu-enquries--body">
                  <p>Email:admission@sitare.org</p>
                  {/* <p>Phone/WhatsApp: +91 12345 67890</p> */}
                </div>
              </div>
            </div>
            <div className="accordion-item cu-enquries--item">
              <h2 className="accordion-header cu-enquries--header" id="flush-headingThree">
                <button className="accordion-button collapsed cu-enquries--button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                Internship Enquiries
                </button>
              </h2>
              <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body cu-enquries--body">
                  <p>Email:internships@sitare.org</p>
                  {/* <p>Phone/WhatsApp: +91 12345 67890</p> */}
                </div>
              </div>
            </div>
            <div className="accordion-item cu-enquries--item">
              <h2 className="accordion-header cu-enquries--header" id="flush-headingFour">
                <button className="accordion-button collapsed cu-enquries--button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                  Campus Location
                </button>
              </h2>
              <div id="flush-collapseFour" className="accordion-collapse collapse show" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body cu-enquries--body">
                  <p>1st floor, Vatika Triangle, Mehrauli-Gurgaon Rd, Sector 28, Maruti Housing Colony, Gurugram, Sarhol, Haryana 122002</p>
                  <p>Google Maps Link: <a href='https://maps.app.goo.gl/ucsJmM4otj6eBp3j9' target='_blank' className='cu-enquries--link'>Sitare University</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs